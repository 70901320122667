.c-service {
  @include tools-font-size(16px);
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 15px;
  background-color: #fff;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    background-color: #f9f9f9;
  }

  &::before {
    content: '';
    display: block;
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #26c6da;
  }

  @include m(clickable) {
    cursor: pointer !important;
  }

  @include e(title) {
    @include tools-font-size(16px);
    font-weight: 500;
    display: inline-block;
  }

  @include e(detail) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 200px);
  }
  @include e(info) {
    width: 100%;
  }

  @include e(duration) {
    font-weight: 300;
    width: 100%;
    color: #aaa;
    white-space: nowrap;
    text-align: center;
  }

  @include e(price) {
    font-weight: 500;
    width: 200px;
    white-space: nowrap;
    text-align: right;
  }
}

@include bp-md {
  .c-service {
    @include e(detail) {
      flex-wrap: wrap;
    }
    @include e(duration) {
      text-align: left;
    }
  }
}

@include bp-sm {
  .c-service {
    @include e(detail) {
      width: 100%;
    }
    @include e(duration) {
      text-align: left;
    }
  }
}
