.c-user-stylist-photo {
  @include e('add') {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    height: 250px;
    border-radius: 8px;
    @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.1));
    @include transition(all 0.3s ease-in-out);
    cursor: pointer;

    &:hover {
      background-color: #f2f2f2;
      @include box-shadow(0 0 5px 2px rgba(0, 0, 0, 0.4));
    }
  }
  @include e('item') {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.1));
    @include transition(all 0.3s ease-in-out);
    cursor: pointer;

    &:hover {
      opacity: 0.9;
      @include box-shadow(0 0 5px 2px rgba(0, 0, 0, 0.4));
    }

    @include s('business') {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 16px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;

      em {
        fontweight: 400;
        color: #ddd;
      }
    }
  }
  @include e('img') {
    display: block;
    width: 100%;
    height: 100%;
  }
}
