.c-auth {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;

  @include e(logo) {
    display: block;
    width: 120px;
    margin: auto;
  }
  @include e(timer) {
    font-size: 16px;
    font-weight: 600;
    min-width: 90px;
    //max-width: 50%;
    height: 40px;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
    color: #fc5622;
    border-radius: 5px;
    border: 2px solid #fc5622;
    margin: 16px auto;
    text-align: center;

    @include s(text) {
      display: block;
      width: 100%;
      cursor: pointer;
    }
  }

  @include e(container) {
    width: 450px;
    max-width: calc(100% - 32px);
    min-height: 100px;
    margin: 16px;
  }
  @include e(image) {
    display: block;
    width: 100px;
    max-width: 90%;
    margin: auto;
  }
  @include e(head) {
    width: 100%;
    padding: 15px;
    h1 {
      @include tools-font-size(24px);
      text-align: center;
    }
    p {
      @include tools-font-size(14px);
      text-align: center;
      color: #999;
    }
  }
  @include e(body) {
    background-color: #fff;
    padding: 16px;
    //@include box-shadow(0 0 5px 1px #ddd);
    border-radius: 10px;
    @include box-shadow(0 2px 10px 1px rgba(0, 0, 0, 0.1));
  }
  @include e(link) {
    @include tools-font-size(14px);
    font-weight: 500;
    display: block;
    color: #1976d2;
    margin-bottom: 10px;
    cursor: pointer;
    //text-align: center;
    @include transition(color 250ms ease-in-out);
    &:hover {
      text-decoration: none;
      color: #2196f3;
    }
    @include m(disabled) {
      color: #999;
      cursor: default;
      &:hover {
        color: #999;
      }
    }
  }
  @include e(links) {
    width: 100%;
    padding: 15px 0;
    & > p {
      @include tools-font-size(14px);
      font-weight: 500;
      display: block;
      color: #333;
      text-align: center;
    }
    & > span,
    & > a {
      text-align: center;
    }
  }
}
