$formWidth: 400px;

.c-promotion {
  @include e(tags-item) {
    display: flex;
    align-items: center;

    @include s(color) {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 6px;
      margin-right: 6px;
    }
  }
  @include e(form-card) {
    @include s(container) {
      display: -ms-flex;
      display: flex;
      width: 100rem;
      //height: 100vh;
      height: 75vh;
      max-width: 100%;
    }
    @include s(sidebar) {
      position: relative;
      width: $formWidth;
      height: 100%;
      //padding: 0 15px;
      //padding: 0 20px;
      overflow: auto;

      form {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    @include s(services) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    @include s(content) {
      height: calc(100% - 70px);
      padding: 10px 20px;
      overflow: auto;
    }
    @include s(footer) {
      width: 100%;
      position: sticky;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 20px;
      margin-top: 16px;
      border-top: 1px solid #eee;
      background-color: #fff;
    }
  }
  @include e(card-wrapper) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: calc(100% - #{$formWidth});
    background-color: #f7f7f7;
    border-left: 1px solid #eee;
    overflow: auto;
  }
  @include e(card-container) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
    margin: auto;
  }

  @include e(email) {
    min-width: 80%;
    background-color: #fff;
    padding: 10px;
    @include box-shadow(0 0 1px 1px #dae4f5);
  }
  @include e(email-subject) {
    @include tools-font-size(16px);
    padding: 10px 0;
  }
  @include e(email-header) {
    padding: 5px 0 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
  }
  @include e(email-info) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    img {
      display: block;
      width: 40px !important;
      height: 40px !important;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
  @include e(email-description) {
    margin-bottom: 15px;
  }
}

@include bp-sm {
  .c-promotion {
    @include e(form-card) {
      @include s(container) {
        height: 100%;
        flex-direction: column-reverse;
      }

      @include s(sidebar) {
        width: 100%;
      }
    }
    @include e(card-wrapper) {
      width: 100%;
      border-left: none;
      border-bottom: 1px solid #eee;
    }
  }
}
