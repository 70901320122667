.c-policy {
  @include s(buttons) {
    margin: 16px 0;
  }
  @include s(content) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
    list-style: none;

    @include bp-sm {
      flex-wrap: wrap;
    }

    @include e(item) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 150px;
      padding: 16px;
      margin: 16px;
      border-radius: 4px;
      border: 1px solid #eee;
      background-color: #e8eaf6;

      @include s(label) {
        font-size: 22px;
        font-weight: 500;
        display: block;
        text-align: center;
      }
      @include s(value) {
        font-size: 22px;
        font-weight: 600;
        display: block;
        margin-top: 16px;
        text-align: center;
      }
    }
  }

  @include s(description) {
    font-size: 16px;
    margin: 16px 0;
  }
}
