.c-alert-modal {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include e(modal-title) {
    font-weight: 600;
    text-align: center;
    color: #dc3545;
  }
  @include e(title) {
    font-weight: 600;
    text-align: center;
  }
  @include e(description) {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
    color: #666;
  }
  @include e(buttons) {
    display: flex;
    align-items: center;
    margin-top: 16px;
    & > * {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
