.c-upload-file {
  width: 50vw;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #eee;
  border: 2px dashed #ccc;

  @include e(input) {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
  @include e(icon) {
    width: 100%;
    max-width: 100%;
    display: block;
    position: relative;
    color: #aaa;
    padding: 8px;
    margin-bottom: 10px;
    text-align: center;

    &::after {
      content: ' ';
      width: 100%;
      height: 20px;
      display: block;
      border-bottom: 2px solid #ccc;
      border-right: 2px solid #ccc;
      border-left: 2px solid #ccc;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    svg {
      font-size: 30px;
    }
  }
  @include e(label) {
    font-weight: 500;
    font-size: 12px;
    display: block;
    margin-bottom: 8px;
    text-align: center;
    color: #757575;
  }

  @include s(preview) {
    position: relative;

    @include e(actions) {
      display: flex;
      position: absolute;
      bottom: 8px;
      right: 0;

      @include s(item) {
        display: block;
        border-radius: 3px;
        color: #fff;
        padding: 8px;
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
        opacity: 0.6;
        margin: 0 5px;
        @include transition(all 250ms ease-in-out);
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@include bp-sm {
  .c-upload-file {
    height: 30vh;
  }
}
