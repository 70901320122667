.c-auth-claim {
  width: 45rem;
  @include e(title) {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin: 28px 0;

    @include m(sm) {
      font-size: 1.7rem;
    }
  }
  @include e(description) {
    //font-size: 1rem;
    //font-weight: 500;
    //text-align: center;
    //color: #666;
    margin: 16px 0;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
    color: #444;
    line-height: 2rem;
  }
  @include e(buttons) {
    margin-top: 32px;
    display: flex;
    align-items: center;
  }

  @include e(image) {
    width: fit-content;
    position: relative;
    margin: 32px auto 0;
    padding: 32px 40px 24px;

    @include m(step) {
      height: 4.5rem;
      width: 4.5rem;
      padding: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ff9800;
      color: #fff;
      border-radius: 50%;
      margin: auto;
      @include box-shadow(0 2px 10px 1px rgba(0, 0, 0, 0.1));
      z-index: 1;
      & > svg {
        font-size: 3rem;
      }
    }

    @include s(main) {
      position: relative;
      height: 10rem;
      width: 10rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #ff9800;
      color: #fff;
      border-radius: 50%;
      @include box-shadow(0 2px 10px 1px rgba(0, 0, 0, 0.2));
      z-index: 1;

      & > svg {
        font-size: 8rem;
      }
    }
    & > i {
      position: absolute;
      @include transition(all 500ms 1000ms ease-in-out);
    }

    @include m(unactive) {
      & > i {
        right: 40% !important;
        left: 40% !important;
        top: 50% !important;
        bottom: 50% !important;
      }
    }

    @include s('1') {
      top: auto;
      right: 10px;
      left: auto;
      bottom: 40px;
      color: #f44336;
      transform: rotate(-15deg);
    }
    @include s('2') {
      font-size: 10px;
      top: auto;
      right: -15px;
      left: auto;
      bottom: 100px;
      color: #2196f3;
      transform: rotate(10deg);
    }
    @include s('3') {
      font-size: 16px;
      right: -10px;
      left: auto;
      top: 30px;
      color: #f9c424;
      transform: rotate(45deg);
    }
    @include s('4') {
      font-size: 14px;
      right: 60px;
      left: auto;
      top: 0;
      color: #673ab7;
      transform: rotate(-35deg);
    }
    @include s('5') {
      font-size: 14px;
      left: 60px;
      top: 0;
      color: #00bcd4;
    }
    @include s('6') {
      font-size: 14px;
      left: -10px;
      right: auto;
      top: 30px;
      color: #9c27b0;
      transform: rotate(45deg);
    }
    @include s('7') {
      font-size: 16px;
      left: -30px;
      right: auto;
      bottom: 80px;
      color: #3f51b5;
      transform: rotate(10deg);
    }
    @include s('8') {
      font-size: 18px;
      left: 5px;
      right: auto;
      bottom: 95px;
      color: #4caf50;
      transform: rotate(-25deg);
    }
    @include s('9') {
      font-size: 12px;
      left: 20px;
      right: auto;
      bottom: 40px;
      color: #ffeb3b;
    }
  }
}
