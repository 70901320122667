.c-third-time {
  //margin-top: 16px;
  margin: 16px;
  @include e(details) {
    list-style: none;

    @include s(label) {
      margin-bottom: 5px;
    }
    @include s(label) {
      font-size: 14px;
      display: inline-block;
      color: #777;
      margin-right: 5px;
    }
    @include s(value) {
      font-size: 16px;
      font-weight: 500;
      display: inline-block;
    }
  }

  @include e(new-times) {
    //width: 100%;
    width: 120px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;

    @include s(container) {
      width: 100%;
      position: relative;
      padding: 0 20px;
    }
    @include s(prev) {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      svg {
        font-size: 24px;
      }
    }
    @include s(next) {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      svg {
        font-size: 24px;
      }
    }
    @include s(item) {
      font-size: 14px;
      font-weight: 500;
      width: 55px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #999;
      color: #333;
      margin-bottom: 10px;
      border-radius: 3px;
      cursor: pointer;

      &:nth-child(2n-1) {
        margin-right: 10px;
      }

      @include m(active) {
        background-color: $mainColor;
        color: #fff;
        border-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  @include e(suggestions) {
    display: flex;
    //flex-direction: row-reverse;
    //justify-content: space-between;
    justify-content: center;
    margin-top: 16px;
  }

  @include e(item) {
    width: 120px;
    min-height: 120px;
    display: flex;
    //align-items: center;
    //justify-content: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    border: 2px solid #999;
    border-radius: 3px;
    cursor: pointer;
    //padding: 8px;
    margin-right: 16px;

    @include transition(all 300ms ease-in-out);

    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: #eee;
    }
    @include m(new) {
      align-items: center;
      justify-content: center;
    }
    @include m(confirmed) {
      cursor: auto;
      &:hover {
        background-color: #fff;
      }
    }
    @include m(declined) {
      background-color: #ccc;
      &:hover {
        background-color: #ccc;
      }
    }

    @include m(active) {
      background-color: $mainColor;
      color: #fff;
      border-color: rgba(0, 0, 0, 0.2);

      .c-third-time__item-body {
        color: #fff;
      }

      &:hover {
        background-color: $mainColor;
      }
    }
    @include m(denied) {
      cursor: auto;
      &:hover {
        background-color: #fff;
      }
    }
    @include s(body) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 8px;
      color: #555;
    }
    @include s(add) {
      font-size: 18px;
      font-weight: 600;
      margin-top: 8px;
      margin-bottom: -16px;
      color: #444;
    }
    @include s(date) {
      //font-size: 15px;
      font-size: 18px;
      font-weight: 600;
    }
    @include s(day) {
      font-size: 10px;
      font-weight: 600;
    }
    @include s(time) {
      font-size: 18px;
      font-weight: 600;
      padding: 8px 0;
      border-top: 2px dashed #ccc;
      //margin-top: 8px;
    }
  }
}
