@import '../settings/settings.global';
@import '../settings/settings.colors';
@import '../objects/objects.grid';
@import '../utilities/utilities.animations';
@import '../tools/tools.bem';
/*------------------------------------------------------------------------------
        Global
------------------------------------------------------------------------------*/
.min-vh-0 {
  min-height: 0 !important;
}
.min-vh-10 {
  min-height: 10vh !important;
}
.min-vh-20 {
  min-height: 20vh !important;
}
.min-vh-30 {
  min-height: 30vh !important;
}
.min-vh-40 {
  min-height: 40vh !important;
}
.min-vh-50 {
  min-height: 50vh !important;
}
.min-vh-60 {
  min-height: 60vh !important;
}
.min-vh-70 {
  min-height: 70vh !important;
}
.min-vh-80 {
  min-height: 80vh !important;
}
.min-vh-90 {
  min-height: 90vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}

.max-w-100 {
  max-width: 100px;
}
.max-w-200 {
  max-width: 200px;
}
.max-w-300 {
  max-width: 300px;
}
.max-w-400 {
  max-width: 400px;
}
.max-w-500 {
  max-width: 500px;
}
.max-w-600 {
  max-width: 600px;
}
.max-w-700 {
  max-width: 700px;
}
.max-w-800 {
  max-width: 800px;
}
.max-w-900 {
  max-width: 900px;
}
.max-w-1000 {
  max-width: 1000px;
}
.max-w-1100 {
  max-width: 1100px;
}
.max-w-1200 {
  max-width: 1200px;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-10 {
  margin-bottom: 1rem;
}
.mb-15 {
  margin-bottom: 1.5rem;
}
.mb-20 {
  margin-bottom: 2rem;
}
.mb-25 {
  margin-bottom: 2.5rem;
}
.mb-30 {
  margin-bottom: 3rem;
}
.mb-35 {
  margin-bottom: 3.5rem;
}
.mb-40 {
  margin-bottom: 4rem;
}
.mb-45 {
  margin-bottom: 4.5rem;
}
.mb-50 {
  margin-bottom: 5rem;
}

.mt-50 {
  margin-top: 5rem;
}

.mt-100 {
  margin-top: 10rem;
}

.pb-50 {
  padding-bottom: 5rem !important;
}
.pb-100 {
  padding-bottom: 10rem !important;
}

.pl-50 {
  padding-left: 5rem !important;
}

.ml-neg-1 {
  margin-left: -0.1rem !important;
}
.mr-neg-1 {
  margin-right: -0.1rem !important;
}
.ml-neg-5 {
  margin-left: -0.5rem !important;
}
.mr-neg-5 {
  margin-right: -0.5rem !important;
}

.color-secondary {
  color: $secondaryColorSub !important;
}
.color-title {
  color: $titleColor !important;
}
.color-text {
  color: $textColor !important;
}

.bg-gray {
  background-color: #ebebe4 !important;
}
.bg-gray-light {
  background-color: #f7f6f5 !important;
}

.font-size-20 {
  font-size: 2rem !important;
}
.font-size-14 {
  font-size: 1.4rem !important;
}
.font-size-12 {
  font-size: 1.2rem !important;
}

.line-height-47 {
  line-height: 4.7rem !important;
}
.line-height-39 {
  line-height: 3.9rem !important;
}
.line-height-22 {
  line-height: 2.2rem !important;
}
.line-height-16 {
  line-height: 1.6rem !important;
}

.min-height-auto {
  min-height: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.cursor-pointer {
  cursor: pointer !important;
  color: #005cbf;
}
.link {
  cursor: pointer !important;
  color: #007bff !important;
}

.align-row > * {
  align-self: center;
}
.main-color {
  color: var(--main-color);
}
.main-bg {
  background-color: var(--main-color);
}
.secondary-color {
  color: var(--secondary-color);
}
.secondary-bg {
  background-color: var(--secondary-color);
}
