.c-head {
  @include s(page) {
    display: flex;
    display: -ms-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;
    margin-right: -15px;
    margin-left: -15px;
    padding: 15px;
    background-color: #fff;
    @include box-shadow(0 1px 1px rgba(0, 0, 0, 0.07));
    h1 {
      font-size: 22px;
      font-weight: 400;
      padding: 5px 0 5px 35px;
      margin-bottom: 0;
      color: #666;
    }
    @include e(element) {
      display: flex;
      display: -ms-flex;
      justify-content: space-between;
      padding: 5px 0;
      max-width: 100%;

      @include s(right) {
        margin-left: auto;
      }

      @include s(left) {
        margin-right: auto;
      }
    }
  }
  @include s(options) {
    display: flex;
    display: -ms-flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    //margin-top: 15px;
    @include e(right) {
      max-width: 100%;
      display: flex;
      display: -ms-flex;
      justify-content: space-between;
      margin-left: auto;
      padding: 5px 0;
    }
    @include e(left) {
      max-width: 100%;
      display: flex;
      display: -ms-flex;
      justify-content: space-between;
      margin-right: auto;
      padding: 5px 0;
    }
  }
}

@include bp-md {
  .c-head {
    @include s(page) {
      @include e(element) {
        flex-wrap: wrap;

        & > * {
          margin-bottom: 10px;
        }
      }
    }
  }
}
