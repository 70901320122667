.c-business-static {
  @include e(profile) {
    display: flex;
    align-items: start;
    margin-top: 8px;
  }

  @include e(profile-detail) {
    padding: 8px 16px;
  }

  @include e(social-media) {
    display: flex;
    align-items: center;
    list-style: none;

    @include s(link) {
      font-size: 15px;
      font-weight: 600;
      width: 35px;
      height: 33px;
      color: #fff;
      border-radius: 5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.3));
      @include transition(all 200ms ease-in-out);

      &:hover {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
        opacity: 0.9;
        color: #fff;
        text-decoration: none;
      }

      @include m('website') {
        background-color: #00838f;
      }
      @include m('facebook') {
        background-color: #1565c0;
      }
      @include m('twitter') {
        background-color: #29b6f6;
      }
      @include m('instagram') {
        background-color: #c2185b;
      }
      @include m('yelp') {
        background-color: #ef5350;
      }
    }
  }

  @include e(item) {
    display: flex;
    align-items: start;
    padding: 6px 0;

    @include s(label) {
      font-size: 14px;
      font-weight: 600;
      display: inline-block;
      min-width: 50px;
      color: #999;
    }

    @include s(val) {
      display: inline-block;
      max-width: calc(100% - 70px);
      font-size: 15px;
      font-weight: 600;
      color: #333;
      margin-left: 8px;
    }

    @include s(empty) {
      font-size: 12px;
      font-weight: 500;
      color: #999;
      margin-left: 8px;
    }
  }

  @include e(image) {
    display: block;
    width: 175px;
    height: 175px;
    max-width: 100%;
    object-fit: cover;
    border-radius: 4px;
    background-color: #eee;
    @include box-shadow(0 0 4px 1px rgba(0, 0, 0, 0.2));
  }
}
