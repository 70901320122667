.c-panel {
  @include grid-row(0);
  &.sidebar-min {
    .c-panel-sidebar {
      @include grid-col-width($sidebarMinWidth);
      .c-panel-sidebar__switch {
        display: none;
        @include bp-sm {
          display: flex;
        }
      }
      .c-panel-sidebar__sub-list-item {
        padding: 12px 15px 12px 25px;
        & > i {
          margin-right: 30px;
        }
      }
    }

    .c-panel-container {
      @include grid-col-width(calc(100% - #{$sidebarMinWidth}));
      margin-left: $sidebarMinWidth;
    }
    .c-panel-header {
      width: calc(100% - #{$sidebarMinWidth});
    }
    .c-panel__bar:nth-child(1),
    .c-panel__bar:nth-child(2) {
      width: 50%;
    }
    .c-panel-sidebar__logo-img {
      &:not(.logo-min-size) {
        display: none;
      }
      &.logo-min-size {
        display: block;
      }
    }
  }

  @include e(burger-menu) {
    //margin: 0 15px;
    //padding: 25px 10px 15px;
    font-size: 17px;
    //color: #007de3;
    color: #fc5622;
    cursor: pointer;
  }
  @include e(bars) {
    width: 25px;
    text-align: center;
  }
  @include e(bar) {
    display: block;
    width: 100%;
    height: 2px;
    //background-color: #9c9c9c;
    background-color: #fc5622;
    margin: 2px 0;
    @include transition(width 350ms ease-in-out);
  }

  @include e(burger-menu) {
  }
}

.c-panel-container {
  @include grid-col-width(calc(100% - #{$sidebarWidth}));
  @include transition(all 350ms ease-in-out);
  margin-left: $sidebarWidth;
  padding-top: 75px;
  //background-color: #eeeeee;
}

.c-panel-body {
  //min-height: 50rem;
  min-height: 50vh;
  //padding: 10px 15px;
  padding: 10px 15px;
}

.c-panel-content {
  position: relative;
  display: block;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 16px;
  //border-radius: 2px;
  //box-shadow: 0 0 5px 1px #DDD;
  border: 1px solid #dce4ec;
  box-shadow: 0 0 12px 2px rgba(237, 239, 241, 0.89);
}

@include bp-sm {
  .c-panel-container {
    @include grid-col-width(100%);
    margin-left: 0;
  }
  .c-panel {
    &.sidebar-min {
      .c-panel-container {
        @include grid-col-width(100%);
        margin-left: 0;
      }
      .c-panel-header {
        width: 100%;
      }
      .c-panel-sidebar {
        @include grid-col-width($sidebarWidth);
        @include transform(translate(0, 0));
      }
    }
  }
}

@include bp-sm {
  .c-panel {
    &.sidebar-min {
      .c-panel-sidebar__logo-img {
        &:not(.logo-min-size) {
          display: block;
        }
        &.logo-min-size {
          display: none;
        }
      }
    }
  }
}
