@import '../tools/tools.grid';

.o-grid {
  .row {
    @include grid-row();
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .no-gutters > .col,
  .no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto {
    @include grid-col();
  }

  .col {
    @include grid-col-width(initial);
  }

  .col-auto {
    @include grid-col-width(auto);
  }

  .col-1 {
    @include grid-col-width(8.333333%);
  }

  .col-2 {
    @include grid-col-width(16.666667%);
  }

  .col-3 {
    @include grid-col-width(25%);
  }

  .col-4 {
    @include grid-col-width(33.333333%);
  }

  .col-5 {
    @include grid-col-width(41.666667%);
  }

  .col-6 {
    @include grid-col-width(50%);
  }

  .col-7 {
    @include grid-col-width(58.333333%);
  }

  .col-8 {
    @include grid-col-width(66.666667%);
  }

  .col-9 {
    @include grid-col-width(75%);
  }

  .col-10 {
    @include grid-col-width(83.333333%);
  }

  .col-11 {
    @include grid-col-width(91.666667%);
  }

  .col-12 {
    @include grid-col-width(100%);
  }
}
