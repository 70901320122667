$imageSize: 185px;
.c-bookable-photo {
  @include e(instagram) {
    @include s(gallery) {
      //width: 600px;
      width: calc((#{$imageSize} + 16px) * 5);
      max-width: 100%;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 8px 0;
      margin: auto;

      @include bp-sm {
        width: 100vw;
      }
    }

    @include s(image) {
      display: block;
      width: 100%;
      height: 100%;
    }
    @include s(item) {
      position: relative;
      width: $imageSize; // 200px - 16px(margin)
      height: $imageSize;
      margin: 8px;
      border-radius: 4px;
      cursor: pointer;
      overflow: hidden;
      @include transition(all 300ms ease-in-out);

      &:hover {
        opacity: 0.8;
      }

      @include bp-sm {
        width: calc(50% - 16px);
        height: auto;
      }
    }

    @include s(icon) {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 8px;
      left: 8px;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      color: #43a047;

      @include m(active) {
        background-color: #fff;
      }
    }
  }

  @include e(methods) {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    @include s(item) {
      width: 100%;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px;
      margin-bottom: 16px;
      border: 2px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
      box-sizing: border-box;
      @include transition(background 200ms ease-in-out);

      &:hover {
        background-color: #eee;
        text-decoration: none;
        * {
          color: #333 !important;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      @include s(title) {
        display: block;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color: #333;
        margin-top: 16px;
        @include transition(all 200ms ease-in-out);
      }

      @include s(icon) {
        font-size: 30px !important;
        color: #999;
        @include transition(all 200ms ease-in-out);
      }
    }
  }
}
