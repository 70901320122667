.c-tour {
  @include e(title) {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    text-align: center;
  }
  @include e(desc) {
    font-size: 16px;
    color: #666;
    margin: 16px 0 0;
    text-align: center;
  }
}
