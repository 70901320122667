.c-notfound-data {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  @include m(width) {
    width: 500px;
    max-width: 90%;
    margin: auto;
  }
  @include e(img) {
    display: -ms-flex;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    img {
      width: 50px;
      height: 50px;
    }
    svg,
    i {
      @include tools-font-size(50px);
    }
  }
  @include e(title) {
    @include tools-font-size(24px);
    font-weight: 500;
    color: #333;
    margin: 1rem 0;
    text-align: center;
  }
  @include e(description) {
    @include tools-font-size(17px);
    font-weight: 400;
    color: #777;
    text-align: center;
    line-height: 25px;
    max-width: 300px;
    margin-bottom: 2rem;
    & > p {
      @include tools-font-size(17px);
      font-weight: 400;
      color: #777;
      text-align: center;
      line-height: 25px;
      max-width: 300px;
      margin-bottom: 0;
    }
  }
}
