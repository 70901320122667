.c-checkout {
  @include s(tip) {
    @include e(form) {
      width: 50%;
      background-color: #fff;
      padding: 16px;
      border-radius: 4px;
      @include box-shadow(0 0 6px 1px rgba(0, 0, 0, 0.1));

      @include m(disabled) {
        position: relative;

        &::after {
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.1);
          z-index: 5;
        }
      }
    }

    @include e(title) {
      font-size: 20px;
      font-weight: 800;
      text-align: center;
    }

    @include e(discount) {
      display: flex;
      align-items: flex-end;
    }
  }
  @include s(discount) {
    @include e(form) {
      display: flex;
      align-items: flex-end;
      width: 100%;
      background-color: #fff;
      padding: 0 16px 16px;
      border-radius: 4px;
      margin-top: 32px;
      @include box-shadow(0 0 6px 1px rgba(0, 0, 0, 0.1));

      @include m(disabled) {
        position: relative;

        &::after {
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.1);
          z-index: 5;
        }
      }
    }

    @include e(discount) {
      display: flex;
      align-items: flex-end;
    }
  }
}
