@font-face {
  font-family: 'Roboto';
  src: url(/assets/fonts/Roboto/Roboto-Medium.ttf) format('ttf');
  font-style: normal;
}

@font-face {
  font-family: 'SansWeb';
  src: url(/assets/fonts/SansWeb-Regular.woff2) format('woff2'), url(/assets/fonts/SansWeb-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SansWeb';
  src: url(/assets/fonts/SansWeb-Bold.woff2) format('woff2'), url(/assets/fonts/SansWeb-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SansWeb';
  src: url(/assets/fonts/SansWeb-RegularItalic.woff2) format('woff2'),
    url(/assets/fonts/SansWeb-RegularItalic.woff) format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SansWeb';
  src: url(/assets/fonts/SansWeb-BoldItalic.woff2) format('woff2'),
    url(/assets/fonts/SansWeb-BoldItalic.woff) format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'SansWeb-Light';
  src: url(/assets/fonts/SansWeb-Light.woff2) format('woff2'), url(/assets/fonts/SansWeb-Light.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SansWeb-Light';
  src: url(/assets/fonts/SansWeb-LightItalic.woff2) format('woff2'),
    url(/assets/fonts/SansWeb-LightItalic.woff) format('woff');
  font-weight: 400;
  font-style: italic;
}
