.c-service-group {
  background-color: #eee;

  @include e(item) {
    background-color: #f7f7f7;
  }

  @include e(item-icon) {
    font-size: 12px;
    margin-right: 15px;
    color: #999;
  }

  @include e(item-head) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //background-color: #FFF;
    padding: 20px 10px 15px;
  }

  @include e(item-info) {
    width: 100%;
    display: -ms-flex;
    display: flex;
    align-items: center;
  }

  @include e(item-title) {
    @include tools-font-size(20px);
    font-weight: 600;
  }

  @include e(item-options) {
    @include tools-font-size(16px);
  }

  @include e(item-services) {
    background-color: #eee;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  }
}
