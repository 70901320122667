$sidebarWidth: 400px;
$sidebarWidthSm: 300px;

.c-appointment {
  @include s(line) {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    border-bottom: 1px solid #eee;

    @include s(container) {
      background-color: #fff;
      border-radius: 5px;
      margin-bottom: 16px;
      @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.1));
    }

    @include e(duration) {
      width: 80px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
    @include e(detail) {
      width: 100%;
      padding: 16px;
    }
    @include e(title) {
      display: block;
      font-size: 16px;
      font-weight: 500;
    }
    @include e(meta) {
      display: block;
      font-size: 14px;
      color: #999;
      margin-top: 5px;
    }
    @include e(price) {
      display: block;
      width: 80px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
  }

  @include e(list-title) {
    @include tools-font-size(20px);
    font-weight: 500;
    padding: 20px 15px;
    border-bottom: 1px solid #ddd;
  }
  @include e(list) {
    .c-appointment__item {
      &:last-child {
        border-bottom: 1px solid #eee;
      }
    }
  }
  @include e(item) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #eee;
    &:last-child {
      border-bottom: none;
    }
  }
  @include e(item-date) {
    @include tools-font-size(16px);
    font-weight: 500;
    width: 50px;
    padding: 5px;
  }
  @include e(item-time) {
    @include tools-font-size(13px);
    width: 100px;
    padding: 5px;
  }
  @include e(item-info) {
    width: 100%;
    padding: 5px;
  }
  @include e(item-title) {
    font-weight: 600;
    color: #000;
  }
  @include e(item-meta) {
    @include tools-font-size(14px);
    font-weight: 300;
    color: #999;
    .staff {
      padding: 0 5px;
    }
  }
  @include e(item-status) {
    @include tools-font-size(10px);
    font-weight: 500;
    color: #4caf50;
    padding: 0 5px;
  }
  @include e(item-price) {
    @include tools-font-size(14px);
    font-weight: 500;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    white-space: nowrap;
  }
}
