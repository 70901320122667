@import '../settings/settings.global';
@import '../settings/settings.colors';
@import '../tools/tools.bem';
@import '../tools/tools.breakpoints';
@import '../tools/tools.font-size';

.o-page {
  @if ($isHeaderFixed) {
    padding-top: $headerHeight;

    @include bp-lg {
      padding-top: $minHeaderHeight;
    }

    @include bp-md {
      padding-top: 60px;
    }
  }

  @include e('seo-content') {
    h1 {
      @include tools-font-size($font-size-h1);
    }

    h2 {
      @include tools-font-size($font-size-h2);
    }

    h3 {
      @include tools-font-size($font-size-h3);
    }

    h4 {
      @include tools-font-size($font-size-h4);
    }

    h5 {
      @include tools-font-size($font-size-h5);
    }

    h6 {
      @include tools-font-size($font-size-h6);
    }

    strong {
      font-weight: bold;
    }

    i {
      font-style: italic;
    }

    ol {
      padding-left: 20px;
    }

    li {
      @include tools-font-size(1.3rem);
    }
  }

  @include m('modal-is-open') {
    height: 100vh;
    overflow: hidden;
  }

  @include e('content') {
    padding-right: 15px;
    padding-left: 15px;
    margin: 3rem auto;
    min-height: calc(100vh - #{$headerHeight} - 520px);

    @include m('center') {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include m('column') {
      flex-direction: column;
    }

    @include bp-sm {
      margin: 0;
    }
  }

  @include s('reject') {
    @include e('container') {
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}
