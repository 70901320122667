.c-image-selector {
  position: relative;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  width: 100%;
  height: 250px;
  margin: auto;

  input[type='file'] {
    display: none;
  }

  @include e(button) {
    display: inline-block !important;
    width: auto;
  }

  @include e(controller) {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 2;
  }

  @include e(file-container) {
    width: 100%;
    text-align: center;
  }

  @include e(image) {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #eee;
    object-fit: cover;

    @include s(container) {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #eee;
    }

    @include s(delete) {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #999;
      opacity: 0;
      @include transition(all 200ms ease-in-out);
      svg {
        font-size: 23px;
      }

      &:hover {
        opacity: 1;
        background: rgba(255, 255, 255, 0.5);
        color: #000;
      }
    }
  }
}
