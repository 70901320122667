@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
}

@mixin box-shadow($property...) {
  -o-box-shadow: $property;
  -moz-box-shadow: $property;
  -webkit-box-shadow: $property;
  box-shadow: $property;
}

@mixin flex-wrap($property) {
  -ms-flex-wrap: $property;
  -webkit-flex-wrap: $property;
  flex-wrap: $property;
}
