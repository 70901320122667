$successColor: #43a047;
$failureColor: #d32f2f;

.c-payment-stripe {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @include m(success) {
    background-color: $successColor;
  }

  @include m(failure) {
    background-color: $failureColor;
  }

  @include e(background) {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include m(filter) {
      filter: saturate(150%) contrast(150%) blur(6px) sepia(10%) grayscale(75%) opacity(0.9);
    }
  }

  @include e(box) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 90%;
    max-height: 90%;
    width: 500px;
    height: 500px;
    position: fixed;
    background-color: #fff;
    border-radius: 5px;
    padding: 16px;

    @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.2));

    @include m(refresh) {
      height: 400px;
    }

    @include m(success) {
      color: $successColor;

      .c-payment-stripe__box-icon {
        background-color: $successColor;
        color: #fff;
      }
    }

    @include m(failure) {
      color: $failureColor;

      .c-payment-stripe__box-icon {
        background-color: $failureColor;
        color: #fff;
      }
    }

    @include s(icon) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      padding: 3px;
      margin: 16px auto;

      svg {
        font-size: 50px;
      }
    }
    @include s(title) {
      width: 100%;
      font-weight: 400;
      font-size: 25px;
      text-align: center;
      padding: 16px;
    }
  }
  @include e(list) {
    width: 100%;
    font-size: 16px;
    color: #333;
    padding: 16px;
    margin-top: 16px;
    list-style: none;
    background-color: #eee;
    border-radius: 5px;

    @include s(item) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
