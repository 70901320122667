.c-checkout-stripe {
  @include e(error) {
    padding: 16px;
    @include s(img) {
      display: block;
      width: 80px;
      height: 80px;
      max-width: 100%;
      margin: auto;
      object-fit: contain;
    }
    @include s(title) {
      text-align: center;
      margin: 16px 0;
    }
    @include s(text) {
      text-align: center;
    }
  }

  @include e(readers-title) {
    font-size: 18px;
    font-weight: 600;
    color: #666;
    padding: 16px;
  }

  @include e(reader) {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #eee;
    background-color: #fff;

    &:last-child {
      border-bottom: 0;
    }

    @include m(clickable) {
      cursor: pointer;
      @include transition(background-color 250ms ease-in-out);
      &:hover {
        background-color: #eee;
      }
    }

    @include s(img) {
      display: block;
      width: 60px;
      height: 60px;
    }
    @include s(detail) {
      display: block;
      width: 100%;
      padding: 0 16px;
    }
    @include s(label) {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: #333;
    }
    @include s(meta) {
      display: block;
      line-height: 18px;
      font-size: 13px;
      color: #999;
    }
    @include s(status) {
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        content: ' ';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #c62828;
        margin-right: 8px;
      }
      @include m(online) {
        &::before {
          background-color: #388e3c;
        }
      }
      @include m(offline) {
        &::before {
          background-color: #fbc02d;
        }
      }
    }
  }
}
