.c-messages {
  width: 100%;
  flex: calc(100% - 350px);
  @include s(container) {
    display: flex;
    height: calc(100vh - 85px);
    //height: calc(100vh - 10rem);
    width: calc(100% + 30px);
    //width: 100%;
    //height: 100%;
    margin: 0 -15px -10px;
  }

  @include e(header) {
    width: 100%;
    //height: 60px;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #f2f2f2;
    background-color: #ffffff;
    padding: 12px 16px;

    @include s(back) {
      //display: none;
      padding: 8px 10px 8px 0;
      margin-right: 5px;
      cursor: pointer;
      svg {
        font-size: 22px;
      }
    }
    @include s(content) {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  @include e(title) {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }

  @include e(add-btn) {
    padding: 8px;
    cursor: pointer;
    @include transition(color 250ms ease-in-out);

    &:hover {
      color: #777;
    }
  }

  @include e(meta) {
    font-size: 11px;
    font-weight: 400;
    color: #999;
  }

  @include e(content) {
    //height: calc(100% - 110px);
    height: calc(100% - 12rem);
    overflow: auto;
    background: #fff;
    position: relative;

    @include s(loading) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
    }
  }

  @include e(attachment) {
    background-color: #fff;
    padding: 5px;
    border: 1px solid #eee;
    width: fit-content;

    @include s(file) {
      display: flex;
      align-items: center;
      max-width: fit-content;
      white-space: nowrap;
      padding: 5px;

      @include s(remove) {
        margin-left: 5px;
        cursor: pointer;
        color: #c62828;
      }

      @include s(download) {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid #999;
        color: #999;
        margin-left: 8px;
        svg {
          font-size: 16px;
        }
        @include transition(all 250ms ease-in-out);

        &:hover {
          border-color: #333;
          color: #333;
        }
      }

      & > svg {
        color: #004d40;
        font-size: 24px;
        margin-right: 5px;
      }
    }
    @include s(image) {
      width: 200px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }

      @include s(remove) {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        color: #c62828;
        padding: 5px;
      }
    }

    & > svg {
      font-size: 24px;
    }
  }

  @include e(bulk) {
    @include s(types) {
      display: flex;
      align-items: center;
      padding: 0 16px 8px 16px;
    }

    @include s(selected) {
      @include s(list) {
        //display: flex;
        //align-items: center;
        width: 100%;
        max-height: 200px;
        overflow: auto;
      }
      @include s(item) {
        //margin-right: 8px;
        margin-right: 8px;
        margin-bottom: 8px;

        span {
          cursor: pointer;
          &:hover {
            color: #ccc;
          }
        }
      }
    }
    @include s(more) {
      font-weight: 600;
      margin: 16px auto;
      padding: 8px 25px;
    }

    @include s(list) {
      height: 100%;
      max-height: 50vh;
      overflow: auto;
    }
    @include s(client) {
      align-items: center;
      @include transition(background-color 200ms ease-in-out);

      @include s(img) {
        margin-right: 16px;
      }

      &:hover {
        background-color: #f7f7f7;
      }

      &:last-of-type {
        &::after {
          content: none;
        }
      }
    }
  }

  @include e(form) {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    //height: 50px;
    height: 6rem;
    background: #fff;
    padding: 5px 16px;
    box-sizing: border-box;
    $this: &;

    @include m(modal) {
      padding: 1rem 0 0;

      #{$this}-input {
        border-radius: 10px;
      }

      svg {
        font-size: 1.7rem;
        color: #666;
      }
    }

    @include s(attachment) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;

      @include s(input) {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
      }

      &:hover {
        & > svg {
          color: #333;
        }
      }
      @include s(preview) {
        position: absolute;
        bottom: 100%;
        left: 50px;
        background-color: #fff;
        padding: 5px;
        border: 1px solid #eee;
        z-index: 5;
      }
    }
    @include s(input) {
      width: 100%;
      border-radius: 20px;
      padding-left: 10px;
      padding-right: 10px;

      textarea {
        resize: none;
      }
    }

    @include s(button) {
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      //top: 5px;
      top: calc(3rem - 20px);
      right: 16px;
      border: 0;
      background: none;
      padding: 0;
      outline: none;
      color: #039be5;
      svg {
        font-size: 24px;
      }
    }
  }

  @include e(client-search) {
    width: 100%;
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }

  @include e(clients) {
    width: 100%;
    //height: calc(100% - 60px);
    height: calc(100% - 5rem);
    overflow: auto;

    @include s(item) {
      position: relative;
      display: flex;
      width: 100%;
      padding: 16px;
      box-sizing: border-box;
      cursor: pointer;
      &::after {
        content: ' ';
        display: block;
        position: absolute;
        width: calc(100% - 32px);
        height: 1px;
        bottom: 0;
        left: 16px;
        background-color: #eee;
      }

      @include m(active) {
        background-color: #f9fbe7;
      }

      @include s(avatar) {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        //margin-right: 8px;
        object-fit: cover;

        &.c-image-name {
          min-width: 40px;
          height: 40px;
          background-color: #ddd;
          font-size: 25px;
        }

        @include s(container) {
          position: relative;
          margin-right: 8px;
        }
      }
      @include s(unread-count) {
        font-size: 11px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        position: absolute;
        top: -5px;
        left: -5px;
        background-color: #fc5622;
        //background-color: #8BC34A;
        color: #fff;
        border-radius: 50%;
        z-index: 10;
      }

      @include s(detail) {
        width: 100%;
      }

      @include s(head) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 3px;
      }
      @include s(date) {
        font-weight: 500;
        text-align: right;
        display: block;
      }
      @include s(name) {
        display: block;
        font-size: 15px;
        font-weight: 600;
      }
      @include s(summary) {
        font-size: 12px;
        display: block;
        width: 250px;
        white-space: nowrap;
        text-overflow: ellipsis;
        box-sizing: border-box;
        overflow: hidden;
      }
    }
  }

  @include e(message) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    margin-bottom: 8px;

    @include s(avatar) {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0 10px;
      object-fit: cover;
    }

    @include s(body) {
      padding: 0 10px;
    }

    @include s(detail) {
      margin-bottom: 10px;
    }

    @include s(name) {
      font-size: 14px;
      font-weight: 600;
      display: inline-block;
      margin-right: 5px;
    }

    @include s(date) {
      font-size: 10px;
      font-weight: 500;
      display: inline-block;
      margin-right: 5px;
      color: #666;
    }

    @include s(text) {
      p {
        margin-bottom: 5px;
      }
    }
  }

  @include s(sidebar) {
    flex: 350px;
    height: 100%;
    width: 350px;
    max-width: 50%;
    background-color: #fff;
    border-left: 2px solid #f2f2f2;
  }
}

@include bp-sm {
  .c-messages {
    flex: 100%;

    @include m(inactive) {
      display: none;
    }

    @include e(header) {
      @include s(back) {
        display: block;
      }
    }
    @include s(sidebar) {
      display: none;
      width: 100%;
      max-width: 100%;
      @include m(active) {
        display: block;
      }
    }
  }
}
