.c-staff {
  @include e(commission) {
    @include s(add) {
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      padding: 8px;
      cursor: pointer;

      &:hover {
        color: #666;
      }
      svg {
        font-size: 26px;
        margin-right: 8px;
      }
    }
    @include s(form-list) {
      padding: 16px;
    }
    @include s(item) {
      position: relative;
      background-color: #f7f7f7;
      border-radius: 8px;
      margin-bottom: 16px;
      padding: 16px 12px 8px;
      border: 1px solid rgba(0, 0, 0, 0.05);

      @include s(remove) {
        cursor: pointer;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 5;
      }
    }
  }
  //@include e(table) {
  //  th {
  //    text-align: center;
  //  }
  //}
  @include e(hours-empty) {
    @include tools-font-size(15px);
    font-weight: 500;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #999;
    padding: 3px 10px;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  @include e(hours) {
    @include tools-font-size(11px);
    font-weight: 500;
    width: 95px;
    display: block;
    background-color: #26c6da;
    color: #fff;
    padding: 3px 10px;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;

    @include m(deactivated) {
      display: -ms-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ccc;
      padding: 10px;
      width: 100%;
      height: 100%;
    }
  }
  @include e(hours-container) {
    width: 100%;
    min-height: 20vh;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
