.c-map {
  @include e(search) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    z-index: 10;
  }

  @include e(selector) {
    position: relative;
    width: 70vw;
    min-width: 100%;
    height: calc(80vh - 80px);

    @include s(marker) {
      width: 40px;
      height: 40px;
      position: absolute;
      top: calc(50% - 20px);
      right: calc(50% - 20px);
      object-fit: contain;
      transition: all 300ms ease-in-out;

      @include m(float) {
        @include transform(translate(0, -10px));
      }
    }
  }
}
