.c-settings {
  @include e(setup) {
    margin-top: 16px;
    @include s(text) {
      display: block;
      font-size: 16px;
      text-align: center;
      margin: 16px 0;
    }
    @include s(btn) {
      font-size: 18px;
      font-weight: 600;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffc02e;
      padding: 10px 24px 10px 6px;
      border: 2px solid #ffc02e;
      border-radius: 7px;
      cursor: pointer;
      margin: 16px auto;
      @include transition(all 300ms ease-in-out);
      &:hover {
        background-color: #ffc02e;
        color: #fff;
      }
    }
  }

  @include s(marketplace) {
    padding: 16px;
    @include e(p) {
      font-size: 14px;
      font-weight: 500;
      margin: 16px 0;
    }
  }

  @include s(password) {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    @include transition(all 300ms ease-in-out);

    @include m(show) {
      max-height: 500px;
      overflow: visible;
    }
  }

  @include s(plans) {
    display: flex;
    align-items: flex-start;
    //justify-content: space-around;
    justify-content: center;

    @include bp-sm {
      flex-wrap: wrap;
    }
    @include e(item) {
      max-width: 100%;
      width: 400px;
      color: #000000;
      background-color: #ffffff;
      border-radius: 10px;
      margin: 0 16px;
      padding: 20px;
      @include box-shadow(0 0 7px 1px rgba(0, 0, 0, 0.08));

      @include m(dark) {
        color: #ffffff;
        background-color: #292f4c;
      }

      @include bp-sm {
        width: 100%;
        margin: 8px 0;
      }

      @include s(icon) {
        display: block;
        width: 35px;
        height: 35px;
        padding: 5px;
        background-color: #f5a700;
        border-radius: 50%;
        margin: 5px auto;

        svg {
          width: 100%;
          height: 100%;
        }
      }
      @include s(title) {
        font-size: 18px;
        font-weight: 600;
        display: block;
        text-align: center;
      }
      @include s(range) {
        display: block;
        text-align: center;
        color: #999;
        font-size: 12px;
        font-weight: 600;
      }

      @include s(content) {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 12px;
        margin-top: 16px;
      }

      @include s(body) {
        //width: 300px;
        //max-width: 100%;
        width: 100%;
        margin: 0 auto 16px;
      }

      @include s(input) {
        color: #000000;
        margin-bottom: 16px;
        label {
          font-size: 11px;
          font-weight: 600;
          text-align: center;
        }
      }
    }

    @include e(benefits) {
      padding: 0;
      margin: 0;
      list-style: none;
      @include s(item) {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid #ccc;

        &:last-child {
          border-bottom: 0;
        }
        @include s(value) {
          font-size: 10px;
          font-weight: 600;
          margin-right: 12px;
          color: #f5a700;

          svg {
            font-size: 20px;
          }
        }
      }
    }
  }

  @include e(image-uploader) {
    flex-direction: row-reverse;
    align-items: flex-start;
    height: auto;
    max-width: none;
    margin: 0 0 15px;

    & > .c-image-selector__controller {
      position: static;
      flex: 100%;
      padding: 15px;
    }
    p {
      font-size: 13px;
      text-align: left;
      color: #666666;
    }

    $imageSelector: '.c-image-selector';
    #{$imageSelector}__file-container {
      text-align: left;
    }

    & > #{$imageSelector}__image-container {
      max-width: 150px;
      height: 85px;
      border-radius: 15px;
      flex: 100%;
      overflow: hidden;
    }

    & > #{$imageSelector}__image-container--circle {
      max-width: 100px;
      height: 100px;
      border-radius: 50%;
      flex: 100%;
      overflow: hidden;
    }

    @include m(lg) {
      & > #{$imageSelector}__image-container--circle {
        max-width: 150px;
        height: 150px;
      }
    }
  }

  @include s(item) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: 0;
    }

    @include m(active) {
      background-color: #ffecb3;
    }
    @include e(img-location) {
      min-width: 100px;
      height: 100px;
      width: 100px;
      border-radius: 5px;
      object-fit: cover;
    }

    @include e(icon) {
      display: inline-block;
      height: 25px;
      width: 25px;
      margin-right: 5px;
    }

    @include e(detail) {
      margin-right: auto;
      margin-left: 16px;
    }

    @include e(title) {
      font-size: 18px;
      display: block;
      padding-bottom: 5px;
      color: #000000;

      &:hover {
        text-decoration: none;
      }
    }

    @include e(description) {
      font-size: 14px;
      color: #999999;
      margin-bottom: 0;
    }
  }

  @include e(buttons) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin: 5px 0;
    @include m(end) {
      justify-content: flex-end;
    }

    .c-button {
      margin: 0 5px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @include e(footer) {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #dddddd;
  }
}
