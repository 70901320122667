$sm: 9px;
$md: 13px;
.c-spinner-dots {
  display: inline-block;
  position: relative;
  width: 80px;
  height: $md;

  @include e(container) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div {
    position: absolute;
    top: 0;
    width: $md;
    height: $md;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 8px;
      animation: c-spinner-dots1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: c-spinner-dots2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: c-spinner-dots2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: c-spinner-dots3 0.6s infinite;
    }
  }

  @include m(sm) {
    width: 62px;
    height: $sm;
    div {
      width: $sm;
      height: $sm;
      &:nth-child(1) {
        left: 5px;
      }
      &:nth-child(2) {
        left: 5px;
      }
      &:nth-child(3) {
        left: 26px;
      }
      &:nth-child(4) {
        left: 49px;
      }
    }
  }
  @include m(dark) {
    div {
      background: #000 !important;
    }
  }
}
@keyframes c-spinner-dots1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes c-spinner-dots3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes c-spinner-dots2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
