.c-checkout {
  @include s(footer) {
    display: flex;
    align-items: flex-start;

    @include e(summary) {
      width: 50%;
      max-width: 400px;
      margin-left: auto;
    }
  }

  @include s(lines) {
    margin-bottom: 16px;
    @include e(item) {
      background-color: #ffffff;
      border-bottom: 0;
      border-radius: 4px;
      padding: 16px;
      margin: 8px 0;
      @include box-shadow(0 0 6px 1px rgba(0, 0, 0, 0.1));

      @include m(refund) {
        position: relative;
        overflow: hidden;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #ef5350;
        }
      }
    }
  }

  @include s(summary) {
    //width: 50%;
    //max-width: 400px;
    //margin-left: auto;
    padding: 16px;

    @include e(item) {
      font-size: 16px;
      font-weight: 400;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #eeee;

      @include m(paid) {
        color: #006064;
      }
      @include m(refund) {
        color: #f44336;
      }
      @include m(refunded) {
        color: #e57373;
      }

      @include s(options) {
        font-size: 10px;
        display: flex;
        align-items: center;
        margin-bottom: -8px;
        color: #999;
      }

      @include s(code) {
        display: block;
        margin-right: 10px;
      }
      @include s(balance) {
        display: block;
      }

      @include s(remove) {
        display: inline-block;
        margin-left: 5px;
        color: red;
        cursor: pointer;
      }

      @include s(info) {
        display: inline-block;
      }

      &:last-child {
        border-bottom: 0;
      }

      @include m(balance) {
        font-size: 16px;
        font-weight: 600;
      }

      @include m(tip) {
        color: #007bff;
      }
    }
  }

  @include s(submit) {
    width: 100%;
    padding: 16px;
  }

  @include s(approaches) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px;

    @include e(item) {
      font-size: 16px;
      font-weight: 500;
      width: calc(50% - 8px);
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #292f4c;
      border-radius: 3px;
      background-color: #292f4c;
      margin: 8px 0;
      color: #fff;
      cursor: pointer;
      @include transition(all 250ms ease-in-out);
      &:hover {
        background-color: #20253c;
      }

      @include m(odd) {
        width: calc(33.3333% - 8px);
        margin: 0;
        //margin-right: auto;
        //margin-left: auto;
        //width: 100%;
      }
      @include m(disabled) {
        background-color: #999999;
        border: 1px solid #666;
        &:hover {
          background-color: #999999;
        }
      }

      @include m(active) {
        background-color: #edb01b;
        color: #333333;
      }
    }
  }
}
