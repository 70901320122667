.c-table {
  overflow-y: auto;

  @include e(title) {
    @include tools-font-size(18px);
    padding: 5px;
  }
  @include m(clickable) {
    tbody {
      tr {
        &:hover {
          background-color: #f7f7f7;
          cursor: pointer;
        }
      }
    }
  }
  @include m(centered) {
    thead,
    tbody {
      tr {
        th,
        td {
          text-align: center;
        }
      }
    }
  }
  @include m(draggable) {
    background-color: #eee;
    thead,
    tbody tr {
      background-color: #fff;
    }
  }

  @include e(link) {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }

  table {
    min-width: 100%;
    width: auto;
  }
  .image,
  .img,
  img {
    @include tools-font-size(18px);
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #eee;
    object-fit: cover;
  }
  img {
    font-size: 10px !important;
    text-align: center;
  }
  th {
    @include tools-font-size(14px);
    padding: 12px 8px;
    color: #333;
  }
  td {
    @include tools-font-size(15px);
    font-weight: 400;
    padding: 16px 8px;
    color: #666;
  }
  thead {
    tr {
      border-bottom: 1px solid #ccc;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #eee;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  tfoot {
    tr {
      border-top: 1px solid #ccc;
    }
  }

  @include m(sm) {
    th {
      @include tools-font-size(13px);
      padding: 6px 3px;
      color: #333;
    }
    td {
      @include tools-font-size(14px);
      font-weight: 300;
      padding: 7px 3px;
      color: #666;
    }
  }

  @include m(bordered) {
    thead {
      th {
        border-right: 1px solid #eeeeee;
        &:last-child {
          border-right: 0;
        }
      }
    }
    tr:last-child {
      td {
        border-bottom: 0;
      }
    }
    td {
      border: 1px solid #eee;
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }

  @include e(item) {
    @include m(disabled) {
      cursor: auto !important;
      background-color: #e9e9e9 !important;
      border-radius: 4px;
      pointer-events: none;
    }
  }
}
