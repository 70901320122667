.c-card {
  @include s(list) {
    display: -ms-flex;
    display: flex;
    @include flex-wrap(wrap);
  }

  @include s(item) {
    position: relative;
    //width: calc(25% - 4px); // main
    //width: 200px;
    //width: 15vw;
    //width: calc((100vw - 250px) / 5 - 24px);
    border-radius: 10px;
    background-color: #fff;
    margin: 8px;
    overflow: hidden;
    @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.1));

    @include m(clickable) {
      cursor: pointer;
      @include transition(all 0.2s ease-in-out);
      &:hover {
        @include box-shadow(0 3px 5px 1px rgba(0, 0, 0, 0.1));
        .c-card-item__image {
          filter: blur(1px);
        }
        .c-card-item__actions {
          opacity: 1;
        }
      }
    }

    @include e(image) {
      display: block;
      height: 100%;
      width: 100%;
      background-image: url('/assets/images/default-image.jpg');
      background-size: cover;
      background-position: center;
      object-fit: cover;

      @include s(container) {
        position: relative;
        width: 100%;
        min-height: 150px;
        //min-height: 200px;
        //min-height: 15vw;
        //min-height: calc((100vw - 250px) / 5 - 24px);
      }
    }
    @include e(detail) {
      display: flex;
      justify-content: space-between;
      padding: 8px;
    }
    @include e(info) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px 4px;
      border-top: aliceblue;
      color: #ccc;
    }
    @include e(status) {
      @include s(published) {
        color: #81c784;
      }
    }

    @include e(actions) {
      display: flex;
      width: 120px;
      position: absolute;
      margin: auto;
      background-color: #fff;
      border-radius: 10px;
      top: 0;
      bottom: 0;
      height: 45px;
      right: 0;
      left: 0;
      opacity: 0;
      overflow: hidden;
      z-index: 2;
      @include transition(opacity 300ms ease-in-out);
      @include box-shadow(1px 0 3px 1px rgba(0, 0, 0, 0.1));

      @include s(item) {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-right: 1px solid #ddd;
        &:last-child {
          border-right: 0;
        }

        &:hover {
          background-color: #eee;
        }

        svg {
          font-size: 25px;
        }

        @include s(text) {
          font-size: 9px;
          font-weight: 600;
          text-align: center;
        }
      }
    }
    @include e(title) {
      font-size: 16px;
      font-weight: 500;
      display: block;
    }
    @include e(price) {
      font-size: 14px;
      font-weight: 400;
      display: block;
      color: #666;
      text-align: center;
      white-space: nowrap;
      margin-left: 5px;
    }
  }
}
