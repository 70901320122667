.c-hours-range {
  margin: 15px 0 20px;
  @include e(header) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  @include e(title) {
    font-size: 14px;
    font-weight: 500;
    //margin-bottom: 15px;
  }
  @include e(edit) {
    cursor: pointer;
    color: #1976d2;
    padding: 6px;
    svg {
      font-size: 1.2rem;
    }
  }
  @include e(form) {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 8px;
      max-width: 120px;
    }
  }

  @include e(input) {
    label {
      padding-block: 0;
    }
    input {
      font-size: 14px;
      padding: 3px 6px;
    }
  }
  @include e(body) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    .c-form__group {
      margin: 0 0 0 15px;
    }
  }
}
