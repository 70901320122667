.c-pagination {
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0 0;

  @include e(list) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    background-color: #fff;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    list-style: none;
  }

  @include e(item) {
    font-size: 13px;
    a {
      display: block;
      width: 30px;
      padding: 3px 0;
      color: #666;
      text-align: center;
      @include transition(color 250ms ease-in-out);

      &:hover {
        color: #000;
        text-decoration: none;
      }

      &.active {
        //color: #1cb5ad;
        color: $mainColor;
      }
    }
  }
}
