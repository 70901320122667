.c-campaign {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px;
  background-color: #eee;

  @include e(item) {
    width: calc(33.3333% - 5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 10px;
    margin-block: 3px;
    //border: 1px solid #ddd;
    border-radius: 5px;
    @include box-shadow(0 0 2px 1px rgba(0, 0, 0, 0.1));

    @include bp-sm {
      width: calc(50% - 5px);
    }

    & > svg {
      font-size: 3rem;
      color: #999;
    }

    @include s(label) {
      font-size: 16px;
      margin-bottom: 5px;
      text-align: center;
    }

    @include s(count) {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
  }
}
