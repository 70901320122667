.c-panel-header {
  display: -ms-flexbox;
  display: flex;
  height: 85px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-bottom: 1px solid #f8f8f9;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - #{$sidebarWidth});
  z-index: 10;
  @include transition(width 350ms ease-in-out);

  @include e(title) {
    @include tools-font-size(20px);
    font-weight: 400;
    // margin: 0 auto 0 16px;
    margin: 0;
    line-height: normal;
    color: #999;
  }
  @include e(head) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0 16px;
  }
  @include e(info) {
    font-size: 18px;
    display: block;
    color: #666;

    cursor: pointer;
    @include transition(color 300ms ease-in-out);

    &:hover {
      color: #000;
    }

    @include s(container) {
      position: relative;
      margin: 0 0 0 16px;
    }

    @include s(player) {
      width: 70vw;
      height: 60vh;
      max-width: 100%;
    }
    @include s(item) {
      display: flex;
      font-weight: 600;
      padding: 12px 16px;
      width: min-content;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: #666;
      }

      svg {
        margin-right: 8px;
      }
    }
    @include s(menu) {
      position: absolute;
      left: 100%;
      top: 0;
      background-color: #fff;
      width: min-content;
      border-radius: 6px;
      margin-left: 16px;
      list-style: none;
      @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.1));
    }
  }

  @include e(items) {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @include e(switch) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include bp-sm {
      display: none;
    }

    .c-form__switch {
      margin: 0 5px;
      .c-form__switch-slider {
        background-color: #bed8fb;
      }
      input:checked + .c-form__switch-slider {
        background-color: $marketplaceColor;
      }
    }

    @include s(labels) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 150px;
    }

    @include s(label) {
      font-size: 11px;
      font-weight: 600;
      color: #ccc;

      @include m(marketplace) {
        color: $marketplaceColor;
      }
      @include m(software) {
        color: #bed8fb;
      }
    }
  }

  @include e(user) {
    flex: 1;
    max-width: 150px;
    display: flex;
    display: -ms-flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 10px;
    cursor: pointer;
    @include transition(opacity 300ms ease-in-out);

    @include s(info) {
      display: flex;
      display: -ms-flex;
      min-width: 100px;
      align-items: center;
    }
    @include s(avatar) {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #eee;
      object-fit: cover;
      -o-object-fit: cover;
    }
    @include s(name) {
      @include tools-font-size(13px);
      display: block;
      margin-left: 8px;
      //white-space: nowrap;

      //@include bp-sm {
      //  display: none;
      //}
    }
  }
  @include e(notifications) {
    flex: 1;
    max-width: 50px;
    @include tools-font-size(18px);
    position: relative;
    padding: 0 15px;
    color: #999;
    @include transition(color 300ms ease-in-out);
    &:hover {
      color: #333;
    }
  }
  @include e(notifications-count) {
    @include tools-font-size(10px);
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -3px;
    right: -6px;
    z-index: 10;
    color: #fff;
    //background-color: #007de3;
    background-color: #fc5622;
    border-radius: 50%;
  }
  @include e(notification) {
    width: 150px;
  }
  //@include e(search) {
  //  @include tools-font-size(18px);
  //  padding: 0 10px;
  //  color: #999;
  //  cursor: pointer;
  //  @include transition(color 300ms ease-in-out);
  //  &:hover {
  //    color: #333;
  //  }
  //}
  @include e(search) {
    flex: 1;
    position: relative;
    max-width: calc(100% - 200px);
    //width: 400px;
    margin: 0 15px;
    form {
      position: relative;
      max-width: 100%;
      width: 400px;
      border: 1px solid #dae4f5;
      background-color: #f8f8f9;
      border-radius: 4px;
      box-sizing: border-box;
      overflow: hidden;
      & > i {
        @include tools-font-size(14px);
        position: absolute;
        top: 0;
        left: 0;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 100%;
        color: #cad1dc;
      }
    }
    input {
      padding: 6px 10px 6px 40px;
      background-color: #f8f8f9;
      line-height: normal;
    }
  }
  @include e(search-autocomplete) {
    display: block;
    max-width: 100%;
    width: 400px;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    padding: 0;
    border-radius: 3px;
    margin-top: 10px;
    @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
    z-index: 10;
    a {
      @include tools-font-size(12px);
      display: block;
      padding: 10px;
      border-bottom: 1px solid #eee;
      text-decoration: none;
      color: #333;
      &:hover {
        color: #999;
      }
    }
  }
}

@include bp-sm {
  .c-panel-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
