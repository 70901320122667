.c-tab {
  $block: &;
  @include m(modal) {
    #{$block}__head {
      background-color: #f7f7f7;
      margin-right: -15px;
      margin-left: -15px;
      border: 0;
      border-radius: 0;
      li {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
      }
    }
  }
  @include m(h-1) {
    #{$block}__body {
      min-height: 10rem;
    }
  }
  @include m(h-2) {
    #{$block}__body {
      min-height: 20rem;
    }
  }
  @include m(h-3) {
    #{$block}__body {
      min-height: 30rem;
    }
  }
  @include m(h-4) {
    #{$block}__body {
      min-height: 40rem;
    }
  }
  @include m(h-5) {
    #{$block}__body {
      min-height: 50rem;
    }
  }
  @include e(head) {
    display: flex;
    display: -ms-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: 10px 0;
    list-style: none;

    border: 1px solid #ddd;
    border-radius: 20px;
    overflow: hidden;

    &.start {
      justify-content: flex-start;
    }
    &.end {
      justify-content: flex-end;
    }

    @include bp-sm {
      align-items: stretch;
    }

    li {
      @include tools-font-size(11px);
      font-weight: 500;
      display: block;
      padding: 5px 20px;
      background-color: #fff;
      color: #666;
      //border: 1px solid #ddd;
      //border-left: none;
      border-right: 1px solid #ddd;
      text-align: center;
      cursor: pointer;
      &:first-child {
        //border-top-left-radius: 3px;
        //border-bottom-left-radius: 3px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-left: 1px solid #ddd;
      }
      &:last-child {
        //border-top-right-radius: 3px;
        //border-bottom-right-radius: 3px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      &.active {
        //background-color: #26C6DA;
        //color: #FFF;
        background-color: #ededed;
      }

      @include bp-sm {
        font-size: 10px;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 6px !important;
      }
    }
  }
  @include e(item) {
    display: none;
    &.active {
      display: block;
    }
  }
}

@include bp-sm {
  .c-tab {
    $block: &;
    @include m(sm-block) {
      #{$block}__head {
        width: 100%;
        li {
          width: 100%;
        }
      }
    }
    @include e(head) {
      li {
        padding: 4px 6px;
        overflow: hidden;
      }
    }
  }
}
