$dark: #333333;
$light: #ffffff;

$heightSize: 3;
$heightType: em;
$height: $heightSize + $heightType;

.c-spinner-box {
  color: $dark;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s !important;
  animation-delay: -0.16s !important;

  @include e(container) {
    //height: $height;
    height: ($heightSize + 10) + $heightType;
  }

  &,
  &:before,
  &:after {
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: $height;
    background: $dark;
  }
  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: '';
  }
  &:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: 1.5em;
  }
  @include m(dark) {
    color: $dark;
    background: $dark;
    &,
    &:before,
    &:after {
      background: $dark;
    }
  }

  @include m(light) {
    color: $light;
    background: $light;
    &,
    &:before,
    &:after {
      background: $light;
    }
  }
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: $height;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: $height;
  }
  40% {
    box-shadow: 0 -2em;
    height: ($heightSize + 1) + $heightType;
  }
}
