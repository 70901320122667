.c-cookie {
  @include e(container) {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    padding: 16px !important;
  }

  @include e(content) {
    font-size: 16px;
    font-weight: 600;
    flex: 1 1 !important;
    width: 100%;
    text-align: center;
    height: auto;
    margin: 0 0 16px !important;
  }

  @include e(buttons) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include e(button) {
    background-color: #fc5622 !important;
    color: #fff !important;
    display: block;
    font-size: 16px;
    font-weight: 600 !important;
    padding: 8px 24px !important;
    outline: none;
    text-decoration: none;
    text-align: center;
    text-align-last: center;
    appearance: none;
    white-space: nowrap;
    border: 0;
    cursor: pointer;
    flex: 0 0 auto;
    margin: 0 15px !important;
    border: none !important;
    border-radius: 3px !important;
    @include transition(background 250ms ease-in-out, color 250ms ease-in-out);
    @include box-shadow(0 0 1px 1px rgba(0, 0, 0, 0.1) !important);

    &:hover {
      background-color: #ff7044 !important;
    }

    @include m(decline) {
      background-color: #fff !important;
      color: #333 !important;
      &:hover {
        background-color: #f9f9f9 !important;
        color: #333 !important;
      }
    }
  }
}
