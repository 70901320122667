@mixin grid-container($padding: 15px) {
  margin-right: auto;
  margin-left: auto;
  padding-right: $padding;
  padding-left: $padding;
  width: 100%;
}

@mixin grid-container-fluid() {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@mixin grid-row($gutter: 10px) {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -$gutter;
  margin-left: -$gutter;
}

@mixin grid-col($gutter: 10px) {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: $gutter;
  padding-left: $gutter;
}

@mixin grid-col-width($width) {
  @if $width == initial {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  } @else {
    -ms-flex: 0 0 $width;
    flex: 0 0 $width;

    @if $width == auto {
      width: auto;
      max-width: none;
    } @else {
      max-width: $width;
    }
  }
}

@mixin grid-order($index) {
  -ms-flex-order: $index;
  order: $index;
}
