.c-panel-setup {
  //width: 100vw;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  //background-image: url('/assets/images/pattern.jpeg');

  @include e('head-back') {
    display: flex;
    align-items: center;
  }
  @include e('stylist-container') {
    margin-top: 100px;
    width: 100%;
    padding: 50px;
  }
  @include e('user-types') {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 24px 0;
  }
  @include e('user-type') {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 28px;
    border-radius: 8px;
    @include box-shadow(0 1px 4px 2px rgba(0, 0, 0, 0.2));
    @include transition(all 0.3s ease-in-out);
    cursor: pointer;

    h4 {
      font-size: 20px;
      font-weight: 600;
      margin-top: 16px;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      color: #666;
      margin: 0;
    }

    &:hover {
      background-color: #f7f7f7;
    }

    &.active {
      background-color: #eee;
    }

    @include e('img') {
      width: 80px;
      height: 80px;
    }
  }

  @include m(settings) {
    .c-panel-setup-content,
    .c-panel-setup-plans {
      width: 100% !important;
      height: 100%;
    }

    @include s(modal) {
      overflow-y: hidden;
      padding: 0;
    }
  }
  @include e(onboard) {
    width: 100%;
    //height: calc(100vh - 165px);

    @media (min-width: 765px) {
      height: calc(100vh - 100px);
      margin-top: 100px;
    }
    @include s(btn) {
      padding-right: 16px;
      svg {
        font-size: 2.3rem;
        margin-left: 8px;
      }
    }
    @include s(video) {
      width: 100%;
      height: 100%;

      @include s(player) {
        width: 100%;
        height: calc(100% - 10px);

        @media (max-width: 768px) {
          margin-top: 30px;
          height: 500px;
        }

        @media (max-width: 500px) {
          margin-top: 30px;
          height: 350px;
        }
      }
      @include s(head) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
      }

      @include s(prev) {
        padding: 8px;
        cursor: pointer;
        svg {
          font-size: 2rem;
        }
      }
    }
    @include s(prev-buttons) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 2rem 0;
    }
    @include s(prev-play) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #fff;
      color: #aaa;
      margin-top: 2rem;
      cursor: pointer;
      svg {
        font-size: 3.2rem;
      }
    }
    @include s(prev-text) {
      position: relative;
      margin: auto;
      width: 70%;
      color: #fff;
      text-align: center;
      //z-index: 10;
      z-index: 1;
      @media (max-width: 765px) {
        width: 90%;
      }

      & > h1 {
        font-size: 5rem;
        text-align: center;
        font-weight: 900;
        margin-bottom: 2rem;
        @media (max-width: 765px) {
          font-size: 4rem;
        }
      }

      & > p {
        text-align: center;
        font-size: 1.8rem;
        line-height: 2.4rem;
        @media (max-width: 765px) {
          font-size: 1.6rem;
        }
      }
    }
    @include s(img) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      filter: brightness(0.6);
    }
    @include s(preview) {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
    }
    @include s(all) {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      @media (max-width: 765px) {
        height: fit-content;
        flex-wrap: wrap;
        align-items: center;
        //padding-bottom: 60px;
      }
    }
    @include s(side) {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      @media (max-width: 765px) {
        padding: 16px;
      }
    }
    @include s(all-btn) {
      position: absolute;
      z-index: 5;
      bottom: 10%;
      @media (max-width: 765px) {
        font-size: 18px;
        font-weight: 600;
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 16px;
        border-radius: 0;
        z-index: 10;
      }
    }
  }
  @include e(question) {
    @include s(container) {
      display: flex;
      width: 100vw;
      height: 100vh;
      margin-top: -3rem;

      @media (max-width: 768px) {
        margin-top: -5rem;
      }
    }
    @include s(wrapper) {
      width: 650px;
      max-width: 90%;
      margin: auto;
      h1 {
        font-weight: 700;
        font-size: 32px;
        text-align: center;
      }
      p {
        font-weight: 500;
        font-size: 15px;
        max-width: 100%;
        text-align: center;
        margin: 16px auto;
      }
    }
    @include s(box) {
      width: 100%;
      padding: 16px 24px;
      border-radius: 7px;
      @include box-shadow(0 1px 7px 2px rgba(0, 0, 0, 0.1));

      label {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  @include e(locations) {
    width: 85vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  @include s(header) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    top: 0;
    padding: 16px 20px;
    background-color: #fff;
    z-index: 2;

    @include e(user) {
      font-weight: 600;
      display: block;
      background-color: #fff;
      padding: 5px 8px 0;
      box-sizing: content-box;
      border-radius: 5px;
      //@include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.2));
      @include transition(all 300ms ease-in-out);
      &:hover {
        @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.2));
      }
    }
    @include e(back) {
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      background-color: #fff;
      padding: 5px 16px;
      box-sizing: content-box;
      border-radius: 5px;
      margin-left: 16px;
      cursor: pointer;
      //@include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.2));
      @include transition(all 300ms ease-in-out);
      &:hover {
        @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.2));
      }

      & > svg {
        margin-right: 8px;
      }
    }

    @include e(logo) {
      height: 50px;
      display: block;
      background-color: #fff;
      padding: 5px;
      box-sizing: content-box;
      border-radius: 5px;
      @include transition(all 300ms ease-in-out);
      &:hover {
        @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.2));
      }
      //@include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.2));

      @include s(image) {
        width: auto;
        height: 100%;
        object-fit: contain;
      }

      @include bp-sm {
        width: 50px;
      }
    }
  }

  @include s(footer) {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    padding-top: 16px;
    margin: auto auto 25px;
  }

  @include s(content) {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
  }
  @include s(title) {
    max-width: 100%;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    padding: 0 16px;
  }
  @include s(plans) {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include e(item) {
    width: 20vw;
    height: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    padding: 16px;
    margin: 10px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.2));
    @include transition(all 400ms ease-in-out);
    &:hover {
      background: #eee;
    }

    @include m(sm) {
      width: 15vw;
      height: 15vw;
    }

    @include m(location) {
      width: 15vw;
      height: auto !important;
      padding: 0;
    }

    @include s(location) {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    @include s(body) {
      padding: 8px;
      width: 100%;
    }

    @include m(software) {
      background-color: $softwareColor;
    }

    @include m(marketplace) {
      background-color: $marketplaceColor;
    }
    @include m(both) {
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffc02e+0,7db9e8+100 */
      background: #ffc02e; /* Old browsers */
      background: -moz-linear-gradient(-45deg, $marketplaceColor 25%, $softwareColor 90%); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        -45deg,
        $marketplaceColor 25%,
        $softwareColor 90%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        135deg,
        $marketplaceColor 25%,
        $softwareColor 90%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

      //&:hover {
      //  background: #ffc02e; /* Old browsers */
      //  background: -moz-linear-gradient(-45deg, #fff 0, #fff 100%);
      //  background: -webkit-linear-gradient(-45deg, #fff 0, #fff 100%);
      //  background: linear-gradient(135deg, #fff 0, #fff 100%);
      //}
      &:hover {
        background: #ffc02e; /* Old browsers */
        background: -moz-linear-gradient(-45deg, $softwareColor 25%, $marketplaceColor 90%); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          -45deg,
          $softwareColor 25%,
          $marketplaceColor 90%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          135deg,
          $softwareColor 25%,
          $marketplaceColor 90%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
    }

    @include s(add-svg) {
      font-size: 30px !important;
      margin-bottom: 16px;
      color: #999;
    }
    @include s(add-title) {
      font-size: 20px;
      font-weight: 900;
      display: flex;
      align-items: center;
      margin-bottom: -32px;
    }
    @include s(title) {
      font-size: 30px;
      font-weight: 600;
      height: 90px;
      display: flex;
      align-items: center;

      @include m(sm) {
        width: 100%;
        font-size: 24px;
        height: 60px;
      }
    }
    @include s(description) {
      display: flex;
      align-items: center;
      height: 45px;
    }

    @include s(icon) {
      position: relative;
      margin-bottom: -30px;
      margin-top: 16px;

      & > svg {
        font-size: 30px;
        color: #999;
      }
    }
  }
}

@include bp-xl {
  .c-panel-setup {
    @include e(item) {
      width: 25vw;
      height: 25vw;
    }
  }
}

@include bp-lg {
  .c-panel-setup {
    @include e(item) {
      width: 30vw;
      height: 30vw;

      @include s(title) {
        font-size: 26px;
        height: 80px;
      }
      @include s(description) {
        height: 45px;
      }
    }
  }
}

@include bp-md {
  .c-panel-setup {
    @include s(plans) {
      @include flex-wrap(wrap);
    }
    @include e(item) {
      width: 45vw;
      height: 45vw;

      @include s(title) {
        font-size: 30px;
        height: 80px;
      }
      @include s(description) {
        height: 45px;
      }
    }
  }
}

@include bp-md {
  .c-panel-setup {
    padding-top: 100px;
  }
}
@include bp-sm {
  .c-panel-setup {
    @include s(plans) {
      @include flex-wrap(wrap);
    }
    @include e(item) {
      width: 75vw;
      height: 75vw;

      @include s(title) {
        font-size: 30px;
        height: 80px;
      }
      @include s(description) {
        height: 45px;
      }
    }
  }
}
