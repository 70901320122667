//$inputShadow: @include box-shadow(0 0 1px 1px #dae4f5);

@mixin inputStyle() {
  //@include box-shadow(0 0 1px 1px #dae4f5);
  @include box-shadow(0 0 2px 1px #ccc);
  border: none;
  border-radius: 4px;
  &--is-focused {
    //@include box-shadow(0 0 1px 1px #26c6da);
    @include box-shadow(0 0 2px 1px #26c6da);
  }
  &:focus {
    //@include box-shadow(0 0 1px 1px #26c6da);
    @include box-shadow(0 0 2px 1px #26c6da);
  }
  @include placeholder {
    color: #8c8c8c;
  }
  &:disabled {
    background-color: #eee !important;
  }
}

.c-form {
  @include e(group-row) {
    display: flex;
    display: -ms-flex;
    @include m(2) {
      flex-wrap: wrap;
      & > .c-form__group-row,
      & > .c-form__group {
        width: 50% !important;
      }
    }
    @include m(3) {
      flex-wrap: wrap;
      & > .c-form__group-row,
      & > .c-form__group {
        width: 33.3333333% !important;
      }
    }
    & > .c-form__group-row,
    & > .c-form__group {
      width: 100%;
      padding-right: 15px;
      &:first-child {
        padding-right: 15px !important;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  @include e(group) {
    margin-bottom: 10px;
    @include m(error) {
      .c-form__select .select__control,
      .l-date-picker-container,
      .c-form__controller,
      .c-form__input {
        @include box-shadow(0 0 1px 1px red !important);
      }
      .c-form__group-description {
        color: red;
      }
    }
    .l-date-picker-container {
      @include tools-font-size(14px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 8px;
      @include inputStyle();
    }
    .l-date-picker__label {
      width: auto;
      padding: 0 5px;
      color: #ccc;
      cursor: pointer;
      @include transition(color 250ms ease-in-out);
      &:hover {
        color: #999;
      }
    }
    .react-datepicker-wrapper {
      width: 100%;
      input {
        text-align: left !important;
        padding: 0 5px;
      }
    }
  }

  @include e(group-description) {
    @include tools-font-size(9px);
    color: #999;
  }

  @include e(group-checkbox) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin: 20px 0;
    label {
      width: auto;
      display: inline-block;
      padding: 0;
    }
  }

  @include e(group-switch) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin: 10px 0;
    label {
      width: auto;
      display: inline-block;
      padding: 0;
    }
  }

  @include e(select) {
    @include s(multi) {
      width: 150px;
      max-width: 100%;
    }
    .select__control {
      @include tools-font-size(14px);
      border: none;
      width: 100%;
      color: #444;
      @include inputStyle();
    }
    @include m(button) {
      .select__control {
        @include tools-font-size(13px);
        min-width: 120px;
        @include box-shadow(0 0 1px 1px rgba(0, 0, 0, 0.1));
        border: none;
        border-radius: 3px;
      }
      .select__indicator {
        padding: 8px 4px;
      }
    }
  }

  @include e(input) {
    @include tools-font-size(14px);
    //@include box-shadow(0 0 1px 1px #CCC);
    width: 100%;
    padding: 7px;
    resize: none;
    @include inputStyle();
  }

  @include e(checkbox-input) {
    display: inline-block;
    height: 16px;
    width: 16px;
    overflow: hidden;
    //margin-top: -4px;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #ccc;
    transition: 0.2s ease-in-out;
    transition-property: background-color, border;
    margin-right: 10px;
    cursor: pointer;
    &:checked {
      background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
      background-color: #1e87f0;
      border-color: transparent;
    }
    @include m(disabled) {
      cursor: not-allowed;
      background-color: #ddd !important;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: #ddd !important;
    }
  }

  @include e(checkbox) {
    flex: 0 0 15px;
    width: 15px !important;
    height: 15px !important;
    display: inline-block;
    //background: #fff;
    background: #26c6da;
    //@include box-shadow(0 0 1px 1px #CCC);
    border: 1px solid #999;
    border-radius: 2px;
    position: relative;
    margin-bottom: 0;
    margin-right: 10px;
    @include e(container) {
      display: -ms-flex;
      display: flex;
      align-items: center;
      .e-checkbox {
        margin-right: 10px;
      }
    }
    input {
      width: 0;
      height: 0;
      background: none;
      overflow: hidden;
      &::before,
      &::after {
        content: ' ';
        height: 2px;
        display: block;
        background: #fff;
        position: absolute;
        opacity: 0;
      }
      &::after {
        width: 11px;
        top: 6px;
        left: 4px;
        transform: rotate(-45deg);
        border-top-right-radius: 2px;
        @include transition(all 100ms 60ms ease-in-out);
      }
      &::before {
        width: 6px;
        top: 8px;
        left: 1px;
        transform: rotate(50deg);
        border-top-left-radius: 2px;
        @include transition(all 100ms ease-in-out);
      }
      &:checked {
        &::before,
        &::after {
          opacity: 1;
        }
      }
    }
  }

  @include e(search-bar) {
    width: 350px;
    max-width: 100%;
    .c-form__group {
      position: relative;
      margin-bottom: 0;
    }
    label {
      position: absolute;
      height: 100%;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666;
      svg,
      i {
        @include tools-font-size(16px);
      }
    }
    .c-form__controller {
      padding: 0 0 0 40px;
      background-color: #ffffff;
    }
    input {
      @include tools-font-size(15px);
      //width: 350px;
      width: 100%;
      //padding: 7px 7px 7px 40px;
      color: #333;
      @include placeholder {
        @include tools-font-size(14px);
        color: #ccc;
      }
    }
  }

  @include e(label) {
    @extend label;
  }

  @include e(label-item) {
    font-weight: 600;
    display: -ms-flex;
    display: flex;
    align-items: center;
    color: #444;

    img {
      display: block;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .detail {
      span {
        @include tools-font-size(13px);
        display: block;
      }
      .meta {
        @include tools-font-size(10px);
        color: #999;
      }
    }
  }

  @include e(value) {
    @extend label;
    @include tools-font-size(16px);
    color: #666;
  }

  @include e(switch) {
    position: relative;
    display: inline-block !important;
    width: 50px !important;
    height: 25px;
    margin-right: 15px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      &:checked + .c-form__switch-slider {
        background-color: #26c6da;
        &::before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
      }
      &:focus + .c-form__switch-slider {
        @include box-shadow(0 0 1px #2196f3);
      }
    }
  }

  @include e(switch-slider) {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: '';
      height: 21px;
      width: 21px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.25));
    }

    @include m(round) {
      border-radius: 50px;
      &:before {
        border-radius: 50%;
      }
    }
  }

  @include e(controller) {
    display: flex;
    display: -ms-flex;
    align-items: center;
    justify-content: space-between;
    @include inputStyle();
    border: none;
    width: 100%;
    //padding: 8px;
    overflow: hidden;

    .ck-editor {
      width: 100% !important;
    }
    .ck.ck-reset_all * {
      border: 0 !important;
    }

    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
      border-color: var(--ck-color-base-border);
      border-width: 0 !important;
      border-top-width: 1px !important;
    }

    @include m(disabled) {
      background-color: #eee;
    }

    @include s(unit) {
      @include tools-font-size(14px);
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      border-left: 1px solid #ccc;
      color: #aaa;

      @include m(clickable) {
        cursor: pointer;

        &:hover {
          color: #666;
        }
      }
    }

    @include s(password) {
      cursor: pointer;

      @include m(active) {
        color: #03a9f4;
      }
    }

    @include s(controls) {
      display: -ms-flex;
      display: flex;
      align-items: center;
      padding: 0 5px;
      button {
        @include tools-font-size(15px);
        background: none;
        border: none;
        color: #999;
        padding: 0 10px;
        @include transition(color 250ms ease-in-out);
        &:hover {
          color: #333;
        }
      }
    }
    textarea,
    input {
      @include tools-font-size(14px);
      width: 100%;
      color: #333;
      border: 0;
      padding: 8px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
      appearance: none;
      @include placeholder {
        color: #8c8c8c;
      }
    }
  }
}

label {
  @include tools-font-size(12px);
  display: block;
  font-weight: 600;
  width: 100%;
  color: #444;
  padding: 5px 0;
  margin-bottom: 0 !important;
  i {
    @include tools-font-size(15px);
  }
}

.e-input-number {
  display: flex;
  display: -ms-flex;
  align-items: center;
  justify-content: space-between;
  @include e(input) {
    display: flex;
    display: -ms-flex;
    align-items: center;
    justify-content: center;
    max-width: 150px;
  }
  @include e(unit) {
    @include tools-font-size(14px);
    width: 100%;
  }
  @include m(unit-before) {
    flex-direction: row-reverse;
    .e-input {
      text-align: left !important;
    }
    .e-input-number__unit {
      text-align: right !important;
    }
  }
  .e-input {
    width: 100%;
    color: #333;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
    appearance: none;
  }
  button {
    @include tools-font-size(15px);
    background: none;
    border: none;
    color: #999;
    @include transition(color 250ms ease-in-out);
    &:hover {
      color: #333;
    }
  }
}

@include bp-sm {
  .c-form {
    @include e(group-row) {
      @include m(2) {
        .c-form__group-row,
        .c-form__group {
          width: 100% !important;
        }
      }
      @include m(3) {
        .c-form__group-row,
        .c-form__group {
          width: 100% !important;
        }
      }
      .c-form__group-row,
      .c-form__group {
        padding-right: 0 !important;
        &:first-child {
          padding-right: 0 !important;
        }
      }
    }
  }
}
