.c-spinner-page {
  @include m(over) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 5;
    .c-spinner-box__container {
      height: auto;
    }
  }
  @include m(fixed) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 5;
    .c-spinner-box__container {
      height: auto;
    }
  }
}
