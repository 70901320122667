//$marketplaceColor: #ffde78;
//$softwareColor: #bed8fb;

.c-setup-system {
  @include s(plans) {
    @include e(container) {
      flex-wrap: wrap;
    }

    @include e(item) {
      //width: calc(50% - 16px);
      min-width: 250px;
      width: calc(33% - 16px);
      //margin: 8px 0;
      margin: 8px;
      //&:nth-child(2n-1) {
      //  margin-right: 16px;
      //}
      .c-settings-plans__item {
        margin: 0;
      }

      @include m(active) {
        .c-settings-plans__item {
          @include box-shadow(0 0 7px 2px #f5a700);
        }
      }

      @include m(inactive) {
        opacity: 0.5;
        @include transition(opacity 250ms ease-in-out);
        .c-settings-plans__item {
          @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.08));
          @include transition(box-shadow 250ms ease-in-out);
          opacity: 1;
          .c-settings-plans__item {
            @include box-shadow(0 0 7px 1px rgba(0, 0, 0, 0.08));
          }
        }
      }

      @include bp-sm {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  }

  @include s(congratulation) {
    text-align: center;

    img {
      display: block;
      width: 200px;
      margin: 16px auto;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: #444;
      margin: 16px 0;
    }
  }

  @include e(map) {
    width: 100%;
    height: 50vh;
  }

  @include e(steps) {
    display: flex;
    width: 100%;
    position: relative;
    list-style: none;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    margin-left: 150px;
    z-index: 3;

    @media (max-width: 768px) {
      margin-left: 0;
      overflow: auto;
      z-index: 1;
    }

    @include s(text) {
      text-align: center;
      margin-top: 3px;
      color: #999;
    }

    @include m(sm) {
      .c-setup-system__steps-text {
        font-size: 9px;
      }
      .c-setup-system__steps-item::after {
        top: 13px;
      }
      .c-setup-system__steps-mark {
        width: 26px;
        height: 26px;
        svg {
          font-size: 16px;
        }
      }
    }

    @include s(mark) {
      width: 30px;
      height: 30px;
      display: flex;
      border: 2px solid #bbb;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-color: #ddd;
      svg {
        font-size: 18px;
        color: #999;
      }
    }

    @include s(item) {
      font-weight: 600;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;

      &:first-child {
        &::after {
          width: 50%;
          left: 50%;
        }
      }

      &:last-child {
        &::after {
          width: 50%;
        }
      }

      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: #aaa;
        z-index: -1;
        left: 0;
        top: 15px;
      }

      @include m(active) {
        .c-setup-system__steps-text {
          color: #000;
        }
        .c-setup-system__steps-mark {
          border-color: $mainColor;
          background-color: #fff;
          svg {
            color: $mainColor;
          }
        }
      }

      @include m(activated) {
        .c-setup-system__steps-text {
          color: #004d40;
        }
        .c-setup-system__steps-mark {
          border-color: #388e3c;
          background-color: #81c784;
          svg {
            color: #004d40;
          }
        }
      }
    }
  }

  @include e(information) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    max-width: 500px;
    //background-image: url("/assets/images/marketplace-circle.svg");
    background-color: $marketplaceColor;
    background-repeat: no-repeat;
    background-size: 150%;
    background-position: center left;

    @include m(software) {
      //  background-image: url("/assets/images/software-circle.svg");
      background-color: $softwareColor;
    }

    @include s(image) {
      display: block;
      height: 100%;
      width: 85%;
      max-width: 100%;
      margin: 0 auto 16px;
      object-fit: contain;
    }
    @include s(video) {
      display: block;
      width: 85%;
      height: 100%;
      margin: 0 auto 16px;
    }
    @include s(main) {
      font-size: 16px;
      font-weight: 600;
      width: 100%;
      margin: auto;
      max-height: 100%;
      height: 500px;
      padding: 16px 16px 16px 32px;

      p {
        text-align: center;
        padding-left: 16px;
      }

      @include m(started-software) {
        background-color: $softwareColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          margin-bottom: 24px;
        }
        p {
          padding-left: 0;
        }
      }
      @include m(started) {
        background-color: $marketplaceColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          margin-bottom: 24px;
        }
        p {
          padding-left: 0;
        }
      }
    }
  }

  @include s(content) {
    width: 55%;
    //height: 100%;
    padding: 16px;
    margin: auto;

    min-height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include e(form) {
    margin: auto;
    width: 100%;
  }

  @include e(preview) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

    margin: auto;
    h1 {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: #444;
      margin-bottom: 30px;
    }
    ul {
      padding-left: 16px;
    }
  }

  @include s(static) {
    min-width: 100%;
    width: 100vw;
    max-width: none;
    height: 100%;
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: row-reverse;
  }
  @include s(modal) {
    display: flex;
    flex-direction: row-reverse;
    width: 90vw;
    max-width: 1200px;

    @include e(close) {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      padding: 10px;
      color: #333;
      z-index: 10;

      svg {
        font-size: 25px;
      }
    }
  }
}

@include bp-sm {
  .c-setup-system {
    @include e(information) {
      display: none;
    }
    @include s(content) {
      width: 100%;
    }
  }
}
