.c-invoice {
  @include s(detail) {
    @include e(client) {
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px solid #ddd;
      @include s(image) {
        font-size: 20px;
        width: 60px;
        height: 60px;
        background-color: #eee;
        border-radius: 50%;
        margin-right: 12px;
      }
      @include s(name) {
        font-size: 16px;
        font-weight: 500;
        display: block;
      }
      @include s(meta) {
        font-size: 12px;
        display: block;
        color: #999;
      }
    }
    @include e(section) {
      border-bottom: 1px solid #ccc;
      padding: 16px 0;

      &:last-child {
        border-bottom: 0;
      }
      @include s(body) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px;
      }
      @include s(title) {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
        padding: 5px 0;
      }
      @include s(value) {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
        padding: 5px 0;
      }
    }

    @include e(list) {
      margin: 0;
      padding: 0;
      list-style: none;
      @include s(item) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 8px;
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }

        @include s(label) {
          font-size: 16px;
          display: block;
        }
        @include s(value) {
          font-size: 16px;
          display: block;
        }
        @include s(meta) {
          font-size: 14px;
          display: block;
          color: #999;
        }
      }
    }
  }
  @include s(factor) {
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 15px;
    border: 1px solid #dce4ec;
    @include box-shadow(0 0 12px 2px rgba(237, 239, 241, 0.9));

    @include e(header) {
      display: -ms-flex;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    @include e(logo) {
      width: 250px;
      height: auto;
      object-fit: contain;
    }

    @include e(address) {
      width: 200px;
      margin-top: 50px;
      span {
        display: block;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
