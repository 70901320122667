.c-permissions {
  overflow: auto;
  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }
  @include e(container) {
    margin-bottom: 2rem;
  }
  @include e(head) {
    display: inline-flex;
    display: -ms-inline-flexbox;
    align-items: center;
    & > li {
      @include tools-font-size(12px);
      font-weight: 500;
      width: 100px;
      text-align: center;
      &:first-child {
        width: 250px;
        text-align: left;
      }
    }

    @include s(title) {
      display: block;
      font-weight: 600;
    }
    @include s(labels) {
      display: flex;
      font-size: 12px;
      align-items: center;
      justify-content: space-evenly;
    }
  }
  @include e(body) {
    display: inline-flex;
    display: -ms-inline-flexbox;
    align-items: center;
    border: 1px solid #999;
    border-bottom: none;
    &:last-child {
      border: 1px solid #999;
    }

    @include s(wrapper) {
      display: flex;
      width: fit-content;
      flex-direction: column;
    }

    & > li {
      @include tools-font-size(12px);
      font-weight: 400;
      display: flex;
      align-items: center;
      //justify-content: center;
      justify-content: space-around;
      width: 100px;
      text-align: center;
      border-left: 1px solid #999;
      padding: 12px 10px;
      &:first-child {
        display: block;
        width: 250px;
        color: #333;
        text-align: left;
        border-left: none;
        padding: 0 8px;
        white-space: nowrap;
        overflow: hidden;
      }
      & > div {
        display: flex;
        flex-direction: column;
      }
      label {
        color: #999;
        font-size: 10px;
      }
      input {
        margin-right: 0;
      }
    }
  }

  @include e(owner) {
    background-color: #eee;
    input {
      background-color: #bbb !important;
    }
  }
}
