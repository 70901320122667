$sidebarWidth: 400px;
$sidebarWidthSm: 300px;

.c-modal-form {
  display: -ms-flex;
  display: flex;
  width: 100rem;
  //height: 100vh;
  height: 80vh;
  max-width: 100%;

  @include e(total) {
    @include s(box) {
      padding: 0;
      width: auto;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @include s(label) {
      @include tools-font-size(16px);
      font-weight: 500;
      display: block;
      text-align: center;
    }
    @include s(input) {
      display: flex;
      width: 175px;
      max-width: 100%;
      margin: 10px;
      border: 1px solid #999;
      border-radius: 4px;

      input {
        @include tools-font-size(26px);
        font-weight: 500;
        height: 100%;
        text-align: center;
      }

      .c-form__controller-unit {
        font-weight: 500;
        font-size: 18px;
        color: #000;
      }
    }
    //@include s(price) {
    //  @include tools-font-size(26px);
    //  font-weight: 500;
    //  display: block;
    //  width: 175px;
    //  max-width: 100%;
    //  text-align: center;
    //  //margin: 10px auto;
    //  margin: 10px;
    //  border: 1px solid #999;
    //  border-radius: 4px;
    //}
    @include s(price) {
      @include tools-font-size(26px);
      font-weight: 500;
      display: block;
      max-width: 100%;
      text-align: center;
      padding: 0 10px;
      margin: 10px;
    }
  }

  @include s(content) {
    height: 100%;
    width: calc(100% - #{$sidebarWidth});
    background-color: #f7f7f7;
    border-right: 1px solid #eee;
    padding: 0 16px;
    overflow: auto;
  }

  @include s(sidebar) {
    width: $sidebarWidth;

    @include bp-sm {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: #fff;
    }
    @include m(minimize) {
      @include bp-sm {
        .c-modal-form-sidebar__content {
          overflow: hidden;
          & > span,
          & > div:not(.c-modal-form-sidebar__client) {
            display: none;
          }
        }
      }
    }
    @include e(minimizer) {
      display: none;
      @include bp-sm {
        position: absolute;
        top: -25px;
        right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: #fff;
        color: #000;
        border-radius: 50%;
        @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.2));
        z-index: 10;
      }
      @include m(minimize) {
        @include transform(rotate(180deg));
      }
    }

    @include e(header) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      border-bottom: 1px solid #eee;

      @include s(title) {
        font-size: 18px;
        font-weight: 600;
        width: 100%;
        margin-bottom: 0;
        margin-inline: auto;
        text-align: center;
      }

      @include s(close) {
        display: block;
        margin-right: auto;
        cursor: pointer;
        padding: 4px 8px;

        svg {
          font-size: 24px;
        }
      }
    }

    @include e(close) {
      width: 30px;
      height: 30px;
      padding: 0;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    @include e(content) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      overflow: auto;

      @include m(header) {
        height: calc(100% - 55px);
      }
    }

    @include e(client) {
      display: flex;
      align-items: center;
      padding: 12px;
      border-bottom: 1px solid #eee;

      @include m(link) {
        cursor: pointer;
        &:hover {
          background-color: #f7f7f7;
        }
      }

      @include s(image) {
        font-size: 20px;
        width: 50px;
        height: 50px;
        background-color: #eee;
        border-radius: 50%;
        margin-right: 12px;
      }

      @include s(name) {
        font-size: 14px;
        font-weight: 500;
        display: block;
      }
      @include s(email) {
        font-size: 12px;
        display: block;
        color: #999;
      }
      @include s(actions) {
        margin-left: auto;
      }

      @include s(action) {
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ccc;
        border-radius: 50%;
        padding: 5px;
        margin-left: 8px;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      @include s(more) {
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        margin-left: auto;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    @include e(clients) {
      @include s(list) {
        height: calc(100% - 210px);
        overflow: auto;
      }

      @include s(walk-in) {
        display: flex;
        align-items: flex-end;
        padding: 12px 12px 20px;
        border-top: 1px solid #eee;
        margin-top: auto;

        @include s(form) {
          width: 100%;
          margin-bottom: 0;
          margin-right: 12px;
        }
      }

      @include s(create) {
        font-size: 12px;
        display: block;
        color: #007bff;
        padding: 12px;
        border-bottom: 1px solid #eee;
        text-align: center;
        cursor: pointer;
      }

      @include s(search) {
        padding: 12px;
        border-bottom: 1px solid #eee;
      }

      @include s(form) {
        padding: 0 12px;
      }
    }

    @include e(footer) {
      display: -ms-flex;
      display: flex;
      width: 100%;
      padding: 12px;
      border-top: 1px solid #eee;
      justify-content: space-between;
      background-color: #ffffff;
      position: sticky;
      bottom: 0;

      & > * {
        width: 100% !important;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@include bp-md {
  .c-modal-form {
    @include s(sidebar) {
      width: $sidebarWidthSm;
    }
    @include s(content) {
      width: calc(100% - #{$sidebarWidthSm});
    }
  }
}

@include bp-sm {
  .c-modal-form {
    flex-direction: column;
    height: calc(100vh - 54px);
    @include m(selected-client) {
      .c-modal-form-sidebar {
        max-height: 100%;
        height: auto;
        border-top: 1px solid #ddd;
      }
      .c-modal-form-content {
        display: block;
      }
    }

    @include s(sidebar) {
      width: 100%;
      height: 100%;

      @include e(clients) {
        @include s(walk-in) {
          position: -webkit-sticky;
          position: sticky;
          bottom: 0;
          right: 0;
          background-color: #ffffff;
        }
      }
    }
    @include s(content) {
      width: 100%;
      border-right: 0;
      display: none;
    }
  }
}
