.c-appointment-modal {
  @include e(date) {
    padding: 16px 0;

    @include s(input) {
      font-size: 18px;
      font-weight: 500;
      display: block;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  @include e(input-note) {
    width: 100%;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 5px;
    @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.1));
  }
  @include e(note) {
    width: 100%;
    display: block;
    padding: 8px 16px;
    margin-bottom: 16px;

    @include s(label) {
      display: block;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 8px;
    }
    @include s(value) {
      display: block;
      font-size: 16px;
    }
    @include s(empty) {
      display: block;
      font-size: 12px;
      color: #666;
    }
  }
  @include e(payment) {
    width: 100%;
    display: block;
    padding: 16px;
    @include s(label) {
      display: block;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 8px;
    }
    @include s(value) {
      display: block;
      font-size: 16px;
      font-weight: 500;
    }
  }
  @include e(line) {
    display: flex;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 5px;
    @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.1));

    @include s(remove) {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 5px;
      color: red;
      cursor: pointer;
      border-radius: 5px;
      @include transition(all 250ms ease-in-out);
      &:hover {
        background-color: #eee;
      }
    }

    @include s(input) {
      width: calc(100% - 200px - 16px);
      min-width: 50%;
      &:nth-child(2n + 1) {
        width: 200px;
        min-width: auto;
        max-width: 50%;
      }
    }
  }
}

@include bp-md {
  .c-appointment-modal {
    @include e(line) {
      @include s(input) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}

@include bp-sm {
  .c-appointment-modal {
    @include e(line) {
      @include s(input) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}
