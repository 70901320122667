.c-staff-view {
  @include e(name) {
    font-weight: 600;
  }

  @include e(meta) {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333;

    @include s(label) {
      color: #666;
      margin-right: 5px;
    }
  }

  @include e(img) {
    width: 130px;
    height: 130px;
    border-radius: 10px;
    margin-right: 16px;
    object-fit: cover;
    background-color: #eee;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  @include e(time) {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 8px;
    border-bottom: 1px solid #eee;
    margin: 3px 0;

    &:last-child {
      border-bottom: 0;
    }

    @include m(close) {
      background-color: #e53935;
      border-radius: 4px;
      span {
        color: #fff !important;
      }
    }

    @include s(label) {
      color: #666;
      @include m(close) {
        font-size: 14px;
      }
    }
    @include s(value) {
      color: #333;
    }
  }

  @include e(section) {
    padding: 16px;
    border-bottom: 1px solid #eee;

    @include s(title) {
      font-weight: 600;
      font-size: 18px;
    }
    @include m(row) {
      display: flex;
      align-items: flex-start;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}
