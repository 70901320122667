.c-section {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  &:last-of-type {
    border-bottom: none;
  }
  @include e(head-back) {
    padding: 4px 8px 4px 0;
    cursor: pointer;
    svg {
      font-size: 24px;
    }
  }
  @include e(head-content) {
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @include e(head-container) {
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  @include e(head-close) {
    @include tools-font-size(18px);
    text-align: center;
    color: #c62828;
    cursor: pointer;
    padding: 10px;
    &:hover {
      color: #e53935;
    }
  }
  @include e(head) {
    padding: 15px 0 5px;
  }
  @include e(head-title) {
    @include tools-font-size(20px);
    margin-bottom: 0.5rem;
  }
  @include e(head-subtitle) {
    @include tools-font-size(16px);
    margin-bottom: 0.5rem;
  }
  @include e(head-description) {
    @include tools-font-size(13px);
    margin-bottom: 0;
    color: #999;
  }
}
