@import '../settings/settings.global';

// Generate a font-size and baseline-compatible line-height.

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin tools-font-size($font-size, $line-height: auto, $important: false) {
  @if (type-of($font-size) == number) {
  } @else {
    @error "`#{$font-size}` needs to be a number.";
  }

  @if ($important == true) {
    $important: !important;
  } @else if ($important == false) {
    $important: null;
  } @else {
    @error "`#{$important}` needs to be `true` or `false`.";
  }

  $value: strip-unit($font-size);

  // We provide a `px` fallback for old IEs not supporting `rem` values.
  @if (unit($font-size) == 'rem') {
    font-size: $value * $global-font-size $important;
    font-size: $font-size $important;
  } @else {
    font-size: $font-size $important;
    font-size: $font-size / $global-font-size * 1rem $important;
  }

  @if ($line-height == 'auto') {
    @if (unit($font-size) == 'rem') {
      line-height: ceil($value / $global-line-height) *
        ($global-line-height / strip-unit($global-font-size))
        $important;
    } @else {
      line-height: ceil($font-size / $global-line-height) * ($global-line-height / $font-size) $important;
    }
  } @else {
    @if (type-of($line-height) == number or $line-height == 'inherit' or $line-height == 'normal') {
      line-height: $line-height $important;
    } @else if ($line-height != 'none' and $line-height != false) {
      @error "`#{$line-height}` is not a valid value for `$line-height`.";
    }
  }
}
