.c-color-palette {
  @include e(section) {
    margin-bottom: 16px;
    opacity: 1;
    @include transition(opacity 250ms ease-in-out);
    @include m(inactive) {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      margin-bottom: 0;
    }
  }

  @include e(head) {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    @include s(back) {
      display: inline-block;
      padding: 8px;
      cursor: pointer;

      svg {
        font-size: 25px;
      }
    }

    @include s(more) {
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-left: auto;
      padding: 16px;
      cursor: pointer;

      svg {
        font-size: 18px;
      }
    }
  }

  @include e(title) {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
  }

  @include e(colors-list) {
    display: flex;
    @include flex-wrap(wrap);
    align-items: flex-start;
    justify-content: flex-start;

    @include m(column) {
      flex-direction: column;
    }
  }
  @include e(color) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    //width: 50px;
    //height: 50px;
    //border-radius: 50%;
    //width: 35px;
    width: calc(12.5% - 10px);
    height: 35px;
    border-radius: 8px;
    margin: 5px;
    cursor: pointer;
    //overflow: hidden;
    @include box-shadow(0 1px 3px 1px rgba(0, 0, 0, 0.2));
    svg {
      font-size: 28px;
      color: #666;
    }

    @include m(selected) {
      width: 45px;
      height: 45px;
    }

    @include m(active) {
      @include box-shadow(0 0 0 2px $mainColor);
    }

    @include s(remove) {
      display: inline-flex;
      position: absolute;
      top: -5px;
      right: -5px;
      background: #fff;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      cursor: pointer;
      border: 1px solid #ccc;
      justify-content: center;
      align-items: center;
      color: #e53935;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      svg {
        font-size: 14px;
      }
    }
    @include s(value) {
      display: block;
      height: 100%;
      width: 100%;
      border-radius: 8px;

      @include m(1) {
        height: 100%;
        width: 100%;
      }
      @include m(2) {
        height: 100%;
        width: 50%;
      }
      @include m(3) {
        height: 50%;
        width: 50%;

        &:last-of-type {
          width: 100%;
        }
      }
      @include m(4) {
        height: 50%;
        width: 50%;
      }
    }
  }
}
