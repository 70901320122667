.dropdown {
  &::after {
    position: absolute;
    right: 0;
  }
  &.open {
    .dropdown-menu {
      display: block;
      bottom: auto;
      right: 0;
      left: auto;
    }
  }
}
