@mixin disabledRow() {
  background-color: #dcdcdc !important;
  border-top-color: #aaa !important;
  .rbc-time-slot {
    border-top-color: #aaa !important;
  }
}

.c-calendar-board {
  @include m('disabled-row') {
    background-color: #bbb !important;
    border-top-color: #bbb !important;
    .rbc-time-slot {
      border-top-color: #ddd !important;
    }
  }
  @for $i from 0 through 48 {
    @include m('start-#{$i}') {
      @for $j from 0 through $i {
        .rbc-timeslot-group:nth-of-type(#{$j + 1}) {
          @include disabledRow();
        }
      }
    }
    @include m('end-#{$i}') {
      @for $j from 0 through $i {
        .rbc-timeslot-group:nth-last-of-type(#{$j + 1}) {
          @include disabledRow();
        }

        &:not(.rbc-today) > .rbc-timeslot-group:nth-last-of-type(#{$j + 2}) {
          @include disabledRow();
        }
        &.rbc-today > .rbc-timeslot-group:nth-last-of-type(#{$j + 3}) {
          @include disabledRow();
        }

        //&.week.rbc-today > .rbc-timeslot-group:nth-last-of-type(#{$j + 5}) {
        //  @include disabledRow();
        //}
      }
    }
  }

  @include m(disabled) {
    .rbc-timeslot-group {
      @include disabledRow();
    }
  }
}
