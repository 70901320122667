$dark: rgba(0, 0, 0, 0.5);
$light: rgba(255, 255, 255, 0.2);

.c-spinner-circle,
.c-spinner-circle:after {
  border-radius: 50%;
  width: 6em;
  height: 6em;
}
.c-spinner-circle {
  margin: 40px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.8em solid $dark;
  border-right: 0.8em solid $dark;
  border-bottom: 0.8em solid $dark;
  border-left: 0.8em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  @include m(lg) {
    margin: 50px auto;
    border-top-width: 1em;
    border-right-width: 1em;
    border-bottom-width: 1em;
    &,
    &::after {
      width: 8em;
      height: 8em;
    }
  }

  @include m(sm) {
    margin: 25px auto;
    border-top-width: 0.5em;
    border-right-width: 0.5em;
    border-bottom-width: 0.5em;
    &,
    &::after {
      width: 3em;
      height: 3em;
    }
  }

  @include m(dark) {
    border-top-color: $dark;
    border-right-color: $dark;
    border-bottom-color: $dark;
  }

  @include m(light) {
    border-top-color: $light;
    border-right-color: $light;
    border-bottom-color: $light;
  }
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
