.c-date-navigation {
  display: -ms-flex;
  display: flex;
  width: 300px;
  height: 40px;
  align-items: center;
  box-shadow: 0 0 1px 1px #eee;
  border-radius: 3px;
  overflow: hidden;
  z-index: 5;
  @include e(today) {
    font-size: 16px;
    font-weight: 500;
    padding: 0.6rem 1.8rem;
  }
  & > button {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #333;
    background-color: #fff;
    padding: 0.6rem 1.5rem;
    height: 100%;
  }
  & > * {
    border-right: 1px solid #eee !important;
    &:last-child {
      border-right: 0 !important;
    }
  }
  .l-date-picker-container {
    height: 100%;
    .react-datepicker-wrapper {
      height: 100%;
      * {
        height: 100%;
      }
    }
  }
}

@include bp-sm {
  .c-date-navigation {
    width: 100%;
  }
}
