@import '../settings/settings.global';
@import '../tools/tools.font-size';

html,
body {
  font-family: $global-font-family;
  font-weight: $global-font-weight;
  font-size: $global-font-size;
  direction: ltr;
  scroll-behavior: smooth;
  margin: 0;
  line-height: 1;
  color: #212529;
  text-align: left;
  overflow: auto !important;
  //background-color: #fff;
  background-color: #f8f8f9 !important;
}

html {
  box-sizing: border-box;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

.div-button {
  outline: 0;
  appearance: none;
  background: none;
  border: none;
}

.dropdown-link {
  @include tools-font-size(14px);
  cursor: pointer;
  white-space: nowrap;
  color: #333;
  &:hover {
    text-decoration: none;
    color: #666;
  }
}

.description {
  @include tools-font-size(13px);
  color: #666;
}

.Toaster__alert_close {
  top: 0 !important;
}
