.c-service-form {
  background-color: #f7f7f7;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: -20px;

  @include e(head) {
    padding: 0 15px;
    .c-section__head-title {
      @include tools-font-size(18px, auto, true);
      margin-bottom: 0;
    }
  }

  @include e(content) {
    background-color: #fff;
    padding: 5px 15px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
  }

  @include e(staff) {
    @include s(link) {
      font-size: 12px;
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: start;
      color: #444;
      padding: 16px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      &:hover {
        color: #444;
        background-color: #f7f7f7;
        text-decoration: none;
      }

      svg {
        margin-right: 10px;
      }
    }
  }
}
