.c-dropdown {
  position: relative;
  z-index: 0;
  &.open {
    z-index: 11;
    .c-dropdown__menu {
      opacity: 1;
      visibility: visible;
      z-index: 1000;
      @include transform(translate(0, 10px));
    }
  }
  @include m(block) {
    width: 100%;
    padding: 0;
    .c-dropdown__menu {
      width: 100%;
      text-align: center;
    }
    .c-dropdown__item {
      font-size: 12px;
      font-weight: 500;
      border-bottom: 1px solid #eee;
      padding: 10px 0;

      &:last-child {
        font-size: 12px;
        border-bottom: 0;
      }
    }
  }
  @include e(main) {
    cursor: pointer;
  }
  @include e(menu) {
    display: block;
    opacity: 0;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 5px 0;
    visibility: hidden;
    z-index: -1;
    @include transform(translate(0, -20px));
    @include transition(all 300ms 10ms ease-in-out);

    @include m(right) {
      right: auto;
      //left: 0;
      //margin-left: auto;
      //left: auto;
    }
    @include m(top) {
      top: auto;
      bottom: 135%;
    }
    @include m(fixed) {
      position: fixed;
      margin-left: -65px;
      top: auto;
      right: auto;
      left: auto;
    }
  }

  @include e(item) {
    padding: 5px 20px;
    display: block;
    font-size: 12px;
    color: #333;
    &:any-link {
      white-space: nowrap;
    }
    &:hover {
      text-decoration: none;
    }
  }
  @include e(item-lg) {
    width: 575px;
  }
  @include e(divider) {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
}
@include bp-md {
  .c-dropdown__item-sm-auto {
    width: auto;
  }
}
