.c-input-phone {
  @include e(select) {
    .select__control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: 80px;
    }
    .select__value-container {
      padding-inline: 3px;
    }
    .select__indicator-separator {
      display: none;
    }
    .select__indicator {
      padding-inline: 0;
    }
  }
  @include e(item) {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #666;

    @include s(image) {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}
