.c-calendar {
  @include e(google-button) {
    min-width: 220px;
    height: 55px;
    max-width: 100%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: none;
    padding: 0;
    background-image: url('/assets/images/google-button/normal.png');
    &:focus {
      background-image: url('/assets/images/google-button/pressed.png');
    }
    &:hover {
      background-image: url('/assets/images/google-button/pressed.png');
    }
    &:disabled {
      background-image: url('/assets/images/google-button/disabled.png');
    }
  }
  @include e(google-btn) {
    font-family: Roboto, sans-serif;
    min-width: 250px;
    max-width: 100%;
    font-size: 14px;
    background-color: #ffffff;
    color: #000;
    padding: 10px 12px;
    border-radius: 0;
    text-align: left;
    justify-content: flex-start;
    text-align-last: start;
    @include box-shadow(0 0 1px 1px rgba(0, 0, 0, 0.15));

    @include s(container) {
      padding: 16px;
      min-height: 120px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h3 {
        text-align: left;
      }
      p {
        font-size: 16px;
        color: #666;
        margin-top: 8px;
        margin-bottom: 16px;
        text-align: left;
      }
    }
    @include s(img) {
      width: 20px;
      height: 20px;
      margin-right: 24px;
    }
  }
  @include e(opt) {
    font-size: 0.95rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: #333;
    white-space: nowrap;
    padding: 10px 16px;
    cursor: pointer;
    @include s(img) {
      width: 25px;
      height: 25px;
      margin-right: 12px;
    }

    @include m(disabled) {
      background-color: #ddd;
    }
  }
  @include e(day-disabled) {
    background-color: #dcdcdc !important;
    .rbc-timeslot-group {
      border-bottom-color: #aaa !important;
    }
    .rbc-time-slot {
      border-top-color: #aaa !important;
    }
  }
  @include e(staff-filter) {
    width: 200px;
    margin-right: 16px;
    z-index: 10;
  }
  @include e(appointment) {
    display: block;
    @include m(day) {
      .c-calendar__appointment-status {
        font-size: 11px;
      }
      .c-calendar__appointment-client,
      .c-calendar__appointment-service {
        font-size: 13px;
      }
    }
    @include m(month) {
      .c-calendar__appointment-status,
      .c-calendar__appointment-client,
      .c-calendar__appointment-service {
        padding: 2px 0;
      }
    }
    @include s(invoice) {
      display: flex;
      align-items: center;
      @include s(icon) {
        position: absolute;
        top: 0;
        right: 0;
        padding: 8px;
        color: #000;
        svg {
          font-size: 16px !important;
        }

        @include m(refund) {
          color: #e53935;
        }
      }
      svg {
        font-size: 14px !important;
        display: inline-block;
        margin-right: 5px;
      }
    }
    @include s(service) {
      font-size: 12px;
      display: block;
      line-height: normal;
      padding: 2.5px 0;
    }
    @include s(client) {
      font-weight: 500;
      font-size: 12px;
      display: block;
      //border-top: 1px solid rgba(0,0,0,0.1);
      padding: 2.5px 0;

      @include m(float) {
        position: absolute;
        top: -25px;
        left: 0;
        background-color: #999;
        padding: 5px 8px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        z-index: 100;

        @include bp-sm {
          display: none;
        }
      }

      @include s(label) {
        font-weight: 600;
        color: #000;
        margin-right: 2.5px;
      }
    }
    @include s(status) {
      font-size: 10px;
      font-weight: 600;
      line-height: normal;
      //border-top: 1px solid rgba(0,0,0,0.1);
      padding: 5px 0;
      display: block;
    }
  }
  @include e(resource) {
    min-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    @include s(name) {
      @include tools-font-size(14px);
      font-weight: 500;
      color: #333;
      margin-bottom: 0;
    }
    @include s(img) {
      font-size: 25px;
      display: block;
      min-width: 3rem;
      width: 3rem;
      height: 3rem;
      background-color: #eee;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;

      @include bp-sm {
        font-size: 20px;
        min-width: 2.2rem;
        width: 2.2rem;
        height: 2.2rem;
      }
    }
  }
}
