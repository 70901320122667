.c-user-stylist-profile {
  width: 100%;
  min-height: 100%;

  @include e('modal') {
    width: 100vw !important;
    height: 100vh;
    cursor: auto;
    background-color: #eee;

    @include s('body') {
      min-height: calc(100% - 150px);
      padding: 0;
    }

    @include m('sm') {
      min-height: auto;
      height: auto;
    }
  }
  @include e('tab') {
    background-color: #eee !important;
  }
  @include e('container') {
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  @include e('section') {
    width: 100%;
    padding: 30px;

    @media (max-width: 768px) {
      padding: 16px;
    }
  }

  @include e('empty') {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
    color: #aaa;

    h3 {
      font-size: 18px;
    }
    svg {
      font-size: 30px;
      margin-bottom: 12px;
    }
  }

  @include e('content') {
    background-color: #fff;
    padding: 16px;
    border-radius: 6px;
    @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.1));

    @include s('title') {
      font-size: 20px;
      font-weight: 500;
      padding: 8px 12px;
      margin-top: 16px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: inline-block;
        background-color: #eee;
        padding: 8px;
        z-index: 5;
      }

      &::after {
        content: ' ';
        position: absolute;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 1px;
        background-color: #ddd;
      }
    }
  }

  @include e('more') {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 8px 0;
    border-top: 1px solid #eee;
    cursor: pointer;
    color: #263238;
    margin-top: 16px;
    transition: color 0.3s ease-in-out;
    svg {
      margin-left: 5px;
    }

    &:hover {
      color: #546e7a;
    }
  }

  @include e('features') {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  @include e('feature') {
    position: relative;
    padding: 16px 0;
    border-bottom: 1px solid #ddd;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }

    span {
      display: block;
    }
    @include s('label') {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }
    @include s('sub') {
      font-size: 16px;
      font-weight: 500;
      font-style: italic;
      color: #999;
    }
    @include s('dates') {
      display: flex !important;
      align-items: center;

      color: #999;
    }
    @include s('date') {
      font-size: 14px;
      font-weight: 500;
      font-style: italic;
      &:first-child {
        margin-right: 8px;
        margin-left: 0 !important;
      }
      &:last-child {
        margin-right: 0 !important;
        margin-left: 8px;
      }
    }

    @include s('actions') {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    @include s('delete') {
      padding: 8px;
      border-radius: 5px;
      cursor: pointer;
      color: #37474f;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: #c62828;
      }
    }

    @include s('edit') {
      padding: 8px;
      border-radius: 5px;
      cursor: pointer;
      color: #37474f;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: #f9a825;
      }
    }
  }

  @include e('description') {
    background-color: #fff;
  }

  @include e('add') {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    margin-top: 8px;
    color: #546e7a;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #263238;
    }

    svg {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      border-radius: 50%;
    }
    @include s('label') {
      font-weight: 600;
      margin-left: 8px;
    }
  }
}
