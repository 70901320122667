.c-fab {
  @include s(button) {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 16px;
    bottom: 16px;
    color: #fff;
    background-color: $mainColor;
    border-radius: 50%;
    cursor: pointer;
    @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.1));
    z-index: 10;

    svg {
      font-size: 26px;
    }
  }
}
