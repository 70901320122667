.c-client {
  @include e(profile) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include e(avatar) {
    max-width: 100%;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #eee;
    border: 1px solid #eee;
    color: #000;
  }
  @include e(name) {
    @include tools-font-size(20px);
    font-weight: 600;
    margin: 10px 0;
  }
  @include e(status) {
    @include tools-font-size(9px, auto, true);
    font-weight: 400 !important;
    padding: 1px 6px !important;
  }

  @include e(options) {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    padding: 2rem 0;
  }
  @include e(options-btn) {
    @include tools-font-size(20px);
    width: 35px;
    text-align: center;
  }

  @include e(info) {
    padding-top: 1rem;
    border-top: 1px solid #eee;
  }

  @include e(info-item) {
    padding: 0.6rem 0;
    strong {
      @include tools-font-size(14px);
      display: block;
    }
    span {
      @include tools-font-size(16px);
      display: block;
      padding: 0.5rem 0;
    }
  }
  @include e(info-desc) {
    margin: 0.6rem 0;
    @include tools-font-size(14px);
    p {
      margin-bottom: 0;
    }
  }

  @include e(payment-money-info) {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
  @include e(payment-money) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-left: 1px solid #eee;
    padding: 2rem 0;
    &:first-child {
      border-left: 0;
    }
    .c-client__payment-no {
      @include tools-font-size(18px);
    }
    .c-client__payment-text {
      @include tools-font-size(16px);
    }
  }

  @include e(payment-no) {
    @include tools-font-size(16px);
    font-weight: 500;
  }

  @include e(payment-text) {
    @include tools-font-size(14px);
    font-weight: 300;
    color: #666;
  }

  @include e(payment-info) {
    display: flex;
    padding: 2rem 0;
  }

  @include e(payment-item) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  @include e(import-content) {
    padding: 15px;
    p {
      @include tools-font-size(15px);
      margin-bottom: 2rem;
      color: #999;
    }
  }
  @include e(import-steps) {
    padding: 0;
    list-style: none;
    li {
      padding-left: 60px;
      display: flex;
      align-items: center;
      position: relative;
      height: 6rem;
      &:before {
        @include tools-font-size(16px);
        font-weight: 500;
        content: attr(data-step);
        display: flex;
        width: 3.2rem;
        height: 3.2rem;
        color: #01579b;
        border: 2px solid #01579b;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        //margin-right: 15px;
        position: absolute;
        left: 0;
      }
      &:after {
        content: '';
        width: 1px;
        height: 2.2rem;
        color: #01579b;
        border-left: 3px dotted #01579b;
        position: absolute;
        left: 1.6rem;
        top: 4.9rem;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
  @include e(import-sources) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @include e(import-source) {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 8px;
    opacity: 0.4;
    border: 1px solid rgba(0, 0, 0, 0.1);
    @include transition(all 250ms ease-in-out);

    &:hover {
      opacity: 0.8;
    }

    @include m(beautster) {
      background-color: $mainColor;
      color: #fff;
    }
    @include m(vagaro) {
      background-color: #e76563;
      color: #fff;
    }
    @include m(fresha) {
      background-color: #101928;
      color: #fff;
    }

    @include m(active) {
      //background-color: $successColor;
      //color: #fff;
      opacity: 1 !important;
      @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.3));
    }
  }
  @include e(import-buttons) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    .c-button {
      width: 100%;
      margin: 0.5rem;
    }
  }
}
