.c-stripe {
  @include s(form) {
    width: 100%;
    height: 100%;

    @include e(content) {
      padding: 16px;
    }

    @include e(input) {
      @include tools-font-size(14px);
      width: 100%;
      padding: 12px;
      resize: none;
      @include inputStyle();
    }
  }
}
