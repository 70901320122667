.c-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  @include m(auto-width) {
    .c-modal-content {
      min-width: 500px;
      width: auto;
      max-width: 100%;
    }
  }
  @include m(lg) {
    .c-modal-content {
      max-width: 700px;
      width: 650px;
    }
  }
  @include m(sticky) {
    .c-modal-header {
      position: sticky;
      position: -webkit-sticky;
      width: 100%;
      top: 0;
      left: 0;
      background-color: #fff;
      z-index: 4;
    }
    .c-modal-footer {
      position: -webkit-sticky;
      position: sticky;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: #fff;
      border-top: 1px solid #eee;
      z-index: 4;
    }
  }
  &.open {
    .c-modal-content {
      @include transform(translate(0, 0px));
      opacity: 1;
    }
  }

  @include e(denied) {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;

    @include s(body) {
      background: #fff;
      padding: 16px;
      border-radius: 5px;
      width: 400px;
      max-width: 100%;
      @include box-shadow(0 0 5px 1px rgba(0, 0, 0, 0.1));
    }
    @include s(title) {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 16px;
      color: #d32f2f;
    }
    @include s(description) {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 0;
    }
    @include s(buttons) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      & > *:first-child {
        margin-right: 8px;
      }
    }
  }

  @include s(content) {
    max-width: 500px;
    width: 500px;
    //max-width: 100%;
    max-height: 95%;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    @include transition(all 300ms ease-in-out);
    @include transform(translate(0, -30px));
    opacity: 0;
    overflow: auto;
  }

  @include s(header) {
    padding: 10px;
    display: flex;
    //align-items: center;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 2px solid #eee;
    @include e(guide) {
      font-size: 18px;
      display: block;
      color: #666;
      margin-left: 10px;

      cursor: pointer;
      @include transition(color 300ms ease-in-out);

      &:hover {
        color: #000;
      }
    }
    @include e(title) {
      width: 100%;
      text-align: center;

      @include s(container) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
      }
    }
    @include e(description) {
      width: 100%;
      text-align: center;
      color: #999;
      margin-bottom: 0;
    }
    @include e(delete) {
      color: #c62828 !important;
      &:hover {
        color: #e53935 !important;
      }
    }

    @include s(head) {
      margin: auto;
    }

    button {
      @include tools-font-size(13px);
      background: none;
      border: none;
      color: #999;
      @include transition(color 250ms ease-in-out);
      &:hover {
        color: #333;
      }
    }
  }

  @include s(body) {
    padding: 0 15px;
    overflow: visible;
    @include m('disabled') {
      position: relative;
    }

    @include e('over-disabled') {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.15);
      z-index: 10;
    }
  }

  @include s(footer) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    margin-top: 1rem;
    .c-button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    @include e(buttons) {
      display: -ms-flex;
      display: flex;
    }
  }
}

@include bp-sm {
  .c-modal {
    @include m(auto-width) {
      .c-modal-content {
        min-width: auto;
      }
    }

    @include s(content) {
      max-height: 100%;
      //height: 100%;
    }
    @include s(header) {
      position: sticky;
      position: -webkit-sticky;
      width: 100%;
      top: 0;
      left: 0;
      background-color: #fff;
      z-index: 2;
    }
    @include s(footer) {
      position: sticky;
      position: -webkit-sticky;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: #fff;
      border-top: 1px solid #eee;
      z-index: 2;
    }
  }
}
