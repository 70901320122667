.c-user-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  background-color: #fff;
  z-index: 10;
  @include transform(translate(100%, 0));
  @include box-shadow(0 0 5px 2px rgba(0, 0, 0, 0.2));
  @include transition(transform 0.5s ease-in-out);
  $this: &;

  @include m('open') {
    @include transform(translate(0, 0));
    cursor: auto;
  }

  @include e('profile') {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    border-bottom: 2px solid #eee;
  }

  @include e('avatar') {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    background-color: #eee;
    border: 3px solid #999;
    @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.3));
  }

  @include e('name') {
    font-size: 18px;
    margin-top: 16px;
  }

  @include e('links') {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  @include e('links-sub') {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #e0e0e0;

    #{$this}__link {
      padding-left: 50px;

      &:hover {
        background-color: #cfd8dc;
      }
    }
    & > li {
      border-bottom: 1px solid #ccc;
      &:last-child {
        border-bottom: 0;
      }
    }
  }

  @include e('link') {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    cursor: pointer;

    &:hover {
      background-color: #eceff1;
    }

    @include s('body') {
      display: flex;
      align-items: center;
      margin-right: auto;
    }

    @include s('label') {
      display: inline-block;
      margin-left: 16px;
    }
  }
}
