a.c-button {
  width: max-content;
}

.c-button {
  display: block;
  @include tools-font-size(14px);
  font-weight: 500;
  background-color: #eee;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #666;
  border: none;
  border-radius: 6px;
  padding: 8px 24px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  text-align: center;
  text-align-last: center;
  appearance: none;
  white-space: nowrap;
  @include transition(background 250ms ease-in-out, color 250ms ease-in-out);

  &:hover {
    color: #fff;
    background-color: #ddd;
    text-decoration: none;
  }
  &:disabled {
    color: #fff;
    background-color: #999999;
    cursor: default;
    &:hover {
      color: #fff;
      background-color: #999999;
    }
  }
  @include s(group) {
    display: -ms-flex;
    display: flex;
    align-items: stretch;
    justify-content: center;
    & > .c-button {
      border-radius: 0;
      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  @include s(image) {
    display: flex;
    align-items: center;
    width: 100%;

    @include e(img) {
      width: 25px;
    }
    @include e(name) {
      width: 100%;
      text-align: center;
    }
  }

  @include m(loading) {
    .c-spinner {
      padding: 5px 0;
    }
  }
  @include m(block) {
    width: 100% !important;
  }
  @include m(sm) {
    @include tools-font-size(12px);
    padding: 6px 20px;
  }
  @include m(lg) {
    @include tools-font-size(16px);
    padding: 10px 23px;
  }
  @include m(light) {
    background-color: #fff;
    color: #333;
    &:hover {
      background-color: #f9f9f9;
      color: #333;
    }
  }
  //@include m(main) {
  //  background-color: #546E7A;
  //  color: #FFF;
  //  &:hover {
  //    background-color: #607D8B;
  //  }
  //}
  @include m(dark) {
    background-color: #263238;
    color: #fff;
    &:hover {
      background-color: #37474f;
    }
  }
  @include m(main) {
    background-color: #fc5622;
    color: #fff;
    &:hover {
      background-color: #ff7044;
    }
  }
  @include m(success) {
    background-color: #43a047;
    color: #fff;
    &:hover {
      background-color: #4caf50;
    }
  }
  @include m(primary) {
    background-color: #1e88e5;
    color: #fff;
    &:hover {
      background-color: #2196f3;
    }
  }
  @include m(danger) {
    background-color: #e53935;
    color: #fff;
    &:hover {
      background-color: #f44336;
    }
  }
  @include m(warning) {
    background-color: #fdd835;
    color: rgba(0, 0, 0, 0.6);
    &:hover {
      background-color: #ffeb3b;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  @include m(info) {
    background-color: #26c6da;
    color: #fff;
    &:hover {
      background-color: #4dd0e1;
    }
  }
}
