.c-list {
  padding: 10px 0;
  @include e(item) {
    @include tools-font-size(14px);
    display: -ms-flex;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
    &:last-child {
      border-bottom: none;
    }
  }
  @include e(info) {
    width: 100%;
    padding: 5px;
  }
  @include e(title) {
    @include tools-font-size(18px);
    font-weight: 500;
    color: #000;
    margin-bottom: 0;
  }
  @include e(description) {
    @include tools-font-size(14px);
    color: #333;
    margin-bottom: 0;
  }
  @include e(metas) {
    @include tools-font-size(13px);
    font-weight: 300;
    color: #999;
    padding: 5px 0;
  }
  @include e(meta) {
    display: inline-block;
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
  @include e(price) {
    @include tools-font-size(15px);
    font-weight: 500;
    display: block;
    width: 100%;
    text-align: center;
  }
  @include e(preview-price) {
    @include tools-font-size(13px);
    font-weight: 500;
    display: block;
    width: 100%;
    text-align: center;
  }
  @include e(origin-price) {
    @include tools-font-size(12px);
    font-weight: 500;
    display: block;
    width: 100%;
    text-decoration: line-through;
    text-align: center;
  }
  @include e(value) {
    @include tools-font-size(15px);
    font-weight: 500;
    display: block;
    width: 100%;
    text-align: center;
  }
  @include e(el-right) {
    display: -ms-flex;
    display: flex;
    width: 150px;
    padding: 5px 0;

    @include m(auto) {
      width: auto;
    }
  }
}
