.c-dashboard {
  @include e(box-wrapper) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 8px -8px 8px;
  }
  @include e(info) {
    padding: 8px;
    display: block;
  }
  @include e(alert) {
    display: flex;
    align-items: center;
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
    padding: 16px;
    margin: 16px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    & > svg {
      font-size: 30px;
      color: rgba(0, 0, 0, 0.3);
    }

    @include m(success) {
      background-color: #dcedc8;
    }
    @include m(warning) {
      background-color: #fff9c4;
    }
    @include m(danger) {
      background-color: #ffcdd2;
    }

    @include s(body) {
      margin-left: 16px;
    }

    @include s(description) {
      margin-bottom: 0;
    }
  }
  @include e(verify) {
    @include s(form) {
      display: flex;
      align-items: center;
    }
    @include s(input) {
      margin: 8px;
      input {
        font-size: 20px;
        text-align: center;
      }
    }
  }
  @include e(box) {
    width: 100%;
    min-height: 90px;
    position: relative;
    display: -ms-flex;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #dce4ec;
    box-shadow: 0 0 12px 2px rgba(237, 239, 241, 0.89);
    margin: 8px 0;
    padding-left: 50px;

    @include m(6) {
      width: calc(16.66666% - 16px);
      margin: 8px;
    }
    @include m(5) {
      width: calc(20% - 16px);
      margin: 8px;
    }
    @include m(4) {
      width: calc(25% - 16px);
      margin: 8px;
    }
    @include m(3) {
      width: calc(33.33333% - 16px);
      margin: 8px;
    }
    @include m(2) {
      width: calc(50% - 16px);
      margin: 8px;
    }
    @include m(1) {
      width: 100%;
      margin: 8px 0;
    }

    @include s(detail) {
      height: 100%;
      padding: 15px;
    }

    @include s(icon) {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 50px;
      background: #f6f8f9;
      text-align: center;
      border-right: 1px solid #dce4ec;
      & > i {
        font-size: 30px;
        color: var(--secondary-color);
        line-height: 90px;
      }
    }

    @include s(title) {
      @include tools-font-size(22px);
      font-weight: 400;
      color: var(--main-color);
    }

    @include s(description) {
      @include tools-font-size(18px);
      font-weight: 500;
      color: $textColor;
      margin: 0;
    }
  }

  @include e(content) {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 1px solid #dce4ec;
    box-shadow: 0 0 12px 2px rgba(237, 239, 241, 0.89);
  }

  @include e(header) {
    width: 100%;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 10px 10px 7px;
    background: #f6f8f9;
    border-bottom: 1px solid #dce4ec;
  }

  @include e(title) {
    @include tools-font-size(16px);
    color: #333;
    margin-bottom: 0.5rem;
  }

  @include e(description) {
    @include tools-font-size(14px);
    font-weight: 400;
    color: #999;
    margin-bottom: 0;
  }

  @include e(options) {
    @include tools-font-size(16px);
    color: #333;
  }

  @include e(body) {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 250px;
    max-height: 450px;
    overflow: auto;

    @include m(services) {
      max-height: none;
      height: calc(100% - 45px);
      height: fit-content;
      justify-content: center;
    }

    & > * {
      width: 100%;
    }
    .c-notfound-data {
      height: 100%;
    }

    @include s(empty) {
      font-size: 20px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: #999;
      margin: auto;
    }
  }
  @include e(chart) {
    width: 100%;
    padding: 10px;
  }
  @include e(body-content) {
    padding: 10px 15px;
  }
  @include e(data-list) {
    list-style: none;
    @include tools-font-size(14px);
    .label {
      display: inline-block;
      color: #999;
    }
    .value {
      display: inline-block;
      color: #333;
      margin-left: 0.5rem;
    }
  }
}

@include bp-md {
  .c-dashboard {
    @include e(box) {
      width: calc(50% - 16px);
    }
    @include e(box-title) {
      @include tools-font-size(20px);
    }
    @include e(box-description) {
      @include tools-font-size(16px);
    }
  }
}

@include bp-sm {
  .c-dashboard {
    @include e(box) {
      width: calc(50% - 16px);
      @include m(sm-6) {
        width: calc(16.66666% - 16px);
        margin: 8px;
      }
      @include m(sm-5) {
        width: calc(20% - 16px);
        margin: 8px;
      }
      @include m(sm-4) {
        width: calc(25% - 16px);
        margin: 8px;
      }
      @include m(sm-3) {
        width: calc(33.33333% - 16px);
        margin: 8px;
      }
      @include m(sm-2) {
        width: calc(50% - 16px);
        margin: 8px;
      }
      @include m(sm-1) {
        width: 100%;
        margin: 8px;
      }
    }
  }
}
