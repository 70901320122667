$elementSeparator: '__';
$modifierSeparator: '--';
$subElementSeparator: '-';

@mixin b($block) {
  .#{$block} {
    @content;
  }
}

@mixin e($element) {
  @at-root {
    #{&}#{$elementSeparator + $element} {
      @content;
    }
  }
}

@mixin m($modifier) {
  @at-root {
    #{&}#{$modifierSeparator + $modifier} {
      @content;
    }
  }
}

@mixin s($sub-element) {
  @at-root {
    #{&}#{$subElementSeparator + $sub-element} {
      @content;
    }
  }
}
