.c-promotion-card {
  width: 300px;
  max-width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  //box-shadow: 0 0 3px 1px rgba(0,0,0,0.2);
  margin: auto;
  img {
    width: 100%;
  }

  @include e(body) {
    padding: 15px;
  }

  @include e(title) {
    @include tools-font-size(16px);
    min-height: 3rem;
    margin-bottom: 1rem;
  }

  @include e(description) {
    @include tools-font-size(12px);
    font-weight: 400;
    min-height: 3rem;
  }

  @include e(price-wrapper) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 15px 0;
    margin-top: 15px;
    border-top: 1px solid #e7e7e7;
  }
  @include e(discount) {
    @include tools-font-size(25px);
    font-weight: 500;
    display: block;
    margin-bottom: 0.5rem;
  }
  @include e(price) {
    @include tools-font-size(16px);
    font-weight: 500;
    display: inline-block;
    padding: 0 5px;
    color: #fc5622;
  }
  @include e(old-price) {
    @include tools-font-size(12px);
    font-weight: 500;
    display: inline-block;
    padding: 0 5px;
    color: #999;
    text-decoration: line-through;
  }
}
