.react-datepicker {
  @include tools-font-size(11px, auto, true);
}
.react-datepicker-wrapper {
  input {
    @include tools-font-size(14px);
    border: none;
    width: 100%;
    padding: 5px;
    //background-color: #f8f8f9;
    background-color: #fff;
    @include placeholder {
      color: #8c8c8c;
    }
  }
}

.height-time {
  .react-datepicker__time-box {
    height: 120px;
  }
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  border: 1px solid #26c6da;
  font-weight: normal;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-range {
  background-color: #80deea !important;
  //background-color: #4DD0E1 !important;
}

.react-datepicker__day--range-end,
.react-datepicker__day--range-start {
  background-color: #4dd0e1 !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range {
  background-color: #b2ebf2 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 13px;
  border-radius: 50% !important;
  padding: 0.5rem;
  width: 2rem !important;
  line-height: 2rem !important;
  margin: 0.25rem !important;
  box-sizing: content-box;
  &:empty {
    visibility: hidden;
  }
}

.l-date-picker-container {
  display: flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  input {
    text-align: center !important;
  }
}
.l-date-picker__label {
  width: auto;
}

.l-date-picker-custom {
  display: flex;
  display: -ms-flex;
  justify-content: center;
  margin: 10px;
  button {
    @include tools-font-size(12px);
    border: 1px solid #ccc;
    color: #666;
    background-color: #fff;
    padding: 0 7px;
  }
  select {
    direction: ltr;
    border: 1px solid #ccc;
    text-align: center;
  }
}
.l-date-picker-arrow {
  width: 5px;
  height: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after,
  &::before {
    width: 100%;
    height: 2px;
    content: ' ';
    display: block;
    position: absolute;
    background-color: #999;
    cursor: pointer;
  }
  &::before {
    transform: rotate(-40deg);
    right: -4px;
  }
  &::after {
    transform: rotate(40deg);
    left: -3px;
  }
}

.c-modal {
  .l-date-picker-container--range {
    .react-datepicker,
    & > div {
      width: 100%;
    }
    .react-datepicker__month-container {
      width: 50%;
    }
  }
}
