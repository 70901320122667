.c-count-down {
  display: flex;
  align-items: center;
  margin: 16px 0;

  @include e(card) {
    font-weight: bold;
    height: 80px;
    max-width: 75px;
    min-width: 75px;
    width: 75px;
    padding: 16px;
    background: #fff;
    border-radius: 6px;
    margin-right: 8px;
    text-align: center;
    @include box-shadow(0 0 3px 1px rgba(0, 0, 0, 0.3));

    &:last-child {
      margin-right: 0;
    }
  }
}
