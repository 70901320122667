.c-gift-card {
  width: 300px;
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #fff;
  margin: auto;
  //box-shadow: 0 0 3px 1px rgba(0,0,0,0.2);
  background: rgb(0, 166, 156);
  background: linear-gradient(-45deg, rgba(0, 166, 156, 1) 0%, rgba(0, 157, 98, 1) 100%);
  @include m(20) {
    background: rgb(0, 166, 156);
    background: linear-gradient(-45deg, rgba(0, 166, 156, 1) 0%, rgba(0, 157, 98, 1) 100%);
  }
  @include m(40) {
    background: rgb(11, 109, 217);
    background: linear-gradient(-225deg, rgba(11, 109, 217, 1) 0%, rgba(95, 171, 255, 1) 100%);
  }
  @include m(60) {
    background: rgb(190, 74, 244);
    background: linear-gradient(-45deg, rgba(190, 74, 244, 1) 0%, rgba(92, 55, 246, 1) 100%);
  }
  @include m(80) {
    background: rgb(237, 176, 27);
    background: linear-gradient(-45deg, rgba(237, 176, 27, 1) 0%, rgba(222, 100, 38, 1) 100%);
  }
  @include m(100) {
    background: rgb(16, 25, 40);
    background: linear-gradient(-225deg, rgba(16, 25, 40, 1) 0%, rgba(32, 48, 71, 1) 100%);
  }

  @include s(list) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100vw;
  }

  img {
    display: block;
    max-width: 100%;
    width: 150px;
    margin: auto;
  }

  @include e(body) {
    padding: 15px;
  }

  @include e(title) {
    @include tools-font-size(16px);
    min-height: 3rem;
    margin-bottom: 1rem;
  }

  @include e(code) {
    width: 100%;
    min-height: 3rem;
    margin-bottom: 1rem;
    text-align: center;

    @include s(copy) {
      display: inline-block;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  @include e(description) {
    @include tools-font-size(12px);
    font-weight: 400;
    min-height: 3rem;
  }

  @include e(price-wrapper) {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
    //margin-top: 15px;
    //border-top: 1px solid #E7E7E7;
  }
  @include e(discount) {
    @include tools-font-size(25px);
    font-weight: 500;
    display: block;
    margin-bottom: 0.5rem;
  }
  @include e(price) {
    @include tools-font-size(14px);
    font-weight: 500;
    display: inline-block;
    padding: 0 5px;
    //color: #fc5622;
  }
  @include e(old-price) {
    @include tools-font-size(12px);
    font-weight: 500;
    display: inline-block;
    padding: 0 5px;
    //color: #999;
    color: #ccc;
    text-decoration: line-through;
  }
  //@include e(limitation) {
  @include e(meta) {
    @include tools-font-size(10px);
    font-weight: 500;
    display: block;
    color: #eeeeee;
    text-align: center;
  }
}
