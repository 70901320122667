@import '../settings/settings.global';

@mixin bp-sm {
  @media screen and (max-width: $bp-sm) {
    @content;
  }
}

@mixin bp-md {
  @media screen and (max-width: $bp-md) {
    @content;
  }
}

@mixin bp-lg {
  @media screen and (max-width: $bp-lg) {
    @content;
  }
}

@mixin bp-xl {
  @media screen and (max-width: $bp-xl) {
    @content;
  }
}

@mixin max-width($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

@mixin min-width($min-width) {
  @media screen and (min-width: $min-width) {
    @content;
  }
}
