.c-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
  @include m(transparent) {
    background-color: transparent;
  }
  @include m(tab-1) {
    z-index: 11;
  }
  @include m(tab-2) {
    z-index: 12;
  }
  @include m(tab-3) {
    z-index: 13;
  }
  @include m(tab-4) {
    z-index: 14;
  }
  @include m(tab-5) {
    z-index: 15;
  }
}
