/* Fonts
   ========================================================================== */
$global-font-size: 0.9rem !default;
$global-line-height: 22px !default;
$global-font-weight: 500 !default;
$global-font-family: SansWeb, Lato, Tahoma, serif !default;

/* Breakpoints
  ========================================================================== */
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;

/* Config
   ======================================================================== */
$isHeaderFixed: false;
$headerHeight: 100px;
$minHeaderHeight: 13rem;

$sidebarMinWidth: 85px;
$sidebarWidth: 275px;

$font-size-h1: 3.4rem !default;
$font-size-h2: 3rem !default;
$font-size-h3: 2.6rem !default;
$font-size-h4: 2.2rem !default;
$font-size-h5: 1.8rem !default;
$font-size-h6: 1.4rem !default;

:root {
  --main-color: #{$marketplaceColor};
  --secondary-color: #{$marketplaceSecondaryColor};
}
.software {
  --main-color: #{$softwareColor};
  //--secondary-color: #{darken($softwareSecondaryColor, 20%)};
  --secondary-color: #{$softwareSecondaryColor};
}
