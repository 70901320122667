.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  z-index: 9 !important;
}

.rbc-calendar {
  background-color: #fff;
  box-shadow: 0 0 1px 1px #dae4f5;
  border-radius: 4px;
  overflow: hidden;
  border: none;
}
.rbc-events-container {
  margin-right: 0 !important;
}

.rbc-time-slot {
  min-height: 25px !important;
}
.rbc-day-slot .rbc-event {
  transition: opacity 150ms, min-height 150ms linear !important;
  &:hover {
    overflow: visible;
    min-height: 70px !important;
    z-index: 9;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  }
}
