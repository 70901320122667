//$sidebarBg: #1a263a;
//$linkColor: #6c7293;
//$logoBg: #1a263a;

//$sidebarBg: #FFFFFF;
//$linkColor: #7f96a3;
//$logoBg: #f8f8f9;
//$linkHoverBg: #f8f8f9;
//$linkHoverColor: #007de3;

//$sidebarBg: #a0b9c6;
//$linkColor: #444;
//$logoBg: #f8f8f9;
//$linkHoverBg: #c0d6e1;
//$linkHoverColor: #007de3;

$sidebarBgMarketplace: #1a1e31;
$sidebarBg: #292f4c;
$logoBg: #292f4c;

$linkColor: #fff;
$linkHoverBg: rgba(0, 0, 0, 0.2);
$linkHoverColor: #eee;

$subLinkColor: #fff;
$subLinkBg: rgba(0, 0, 0, 0.3);
$subLinkHoverBg: rgba(0, 0, 0, 0.4);
$subLinkHoverColor: #eee;

.c-panel-sidebar {
  @include grid-col-width($sidebarWidth);
  @include transition(all 350ms ease-in-out);
  width: $sidebarWidth;
  position: fixed;
  left: 0;
  height: 100%;
  top: 0;
  background-color: $sidebarBg;
  color: $linkColor;
  overflow: hidden;

  @include m(marketplace) {
    background-color: $sidebarBgMarketplace;

    .c-panel-sidebar__logo {
      background-color: $sidebarBgMarketplace;
    }
  }

  @include e(switch) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 16px;
    margin-bottom: 16px;

    .c-form__switch {
      margin: 0 5px;
      .c-form__switch-slider {
        background-color: #bed8fb;
      }
      input:checked + .c-form__switch-slider {
        background-color: $marketplaceColor;
      }
    }

    @include s(label) {
      font-size: 14px;
      font-weight: 600;
      color: #ccc;

      @include m(marketplace) {
        color: $marketplaceColor;
      }
      @include m(software) {
        color: #bed8fb;
      }
    }
  }
  //@include e(switch) {
  //  //display: none;
  //  //@include bp-sm {
  //  display: flex;
  //  align-content: center;
  //  justify-content: center;
  //  position: relative;
  //  padding: 0 16px;
  //  margin-bottom: 16px;
  //
  //  .c-panel-header__switch-labels {
  //    width: 100%;
  //    padding: 0 16px;
  //  }
  //  .c-form__switch {
  //    position: absolute;
  //  }
  //  //}
  //}

  @include e(logo) {
    display: block;
    background: $logoBg;
    padding: 15px 25px;
  }
  @include e(logo-img) {
    display: block;
    width: 150px;
    height: 52px;
    object-fit: contain;
    @include transition(all 0 350ms ease-in-out);
    &.logo-min-size {
      width: 35px;
      display: none;
    }
  }

  @include e(setup) {
    width: 275px;
    display: block;
    margin: 16px auto 16px;
    padding: 0 15px 0 25px;
    color: $marketplaceColor;
    cursor: pointer;
    @include transition(color 400ms ease-in-out);
    &:hover {
      color: $marketplaceSecondaryColor;
    }

    @include m(marketplace) {
      color: $marketplaceColor;
      &:hover {
        color: $marketplaceSecondaryColor;
      }
    }
    @include m(software) {
      color: #289eff;
      &:hover {
        color: $softwareSecondaryColor;
      }
    }

    @include s(text) {
      font-size: 16px;
      font-weight: 600;
      display: inline-block;
    }
    @include s(img) {
      width: 22px;
      text-align: center;
      display: inline-block;
      margin-right: 30px;
      margin-left: 7px;
    }
  }

  @include e(list) {
    height: calc(100% - 82px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    color-scheme: dark;

    @include s(introduce) {
      position: relative;
      padding-top: 16px;

      @include s(head) {
        font-size: 14px;
        font-weight: 600;
        width: 275px;
        display: block;
        margin: 8px auto 16px;
        padding: 0 15px 0 25px;
        color: #fff;
      }

      @include s(text) {
        font-size: 16px;
        display: inline-block;
        //margin-left: 8px;
        color: $marketplaceColor;

        @include m(software) {
          color: #bed8fb;
        }
      }

      @include s(img) {
        width: 22px;
        text-align: center;
        display: inline-block;
        margin-right: 30px;
        margin-left: 7px;
      }
    }

    @include s(item) {
      position: relative;

      @include m(active) {
        .c-panel-sidebar__sub-list {
          //display: block;
          max-height: 500px;
          //overflow: auto;
        }
      }
    }

    @include s(link) {
      @include tools-font-size(16px);
      width: $sidebarWidth;
      display: block;
      padding: 12px 15px 12px 25px;
      color: $linkColor;
      text-decoration: none;
      @include transition(all 300ms ease-in-out);

      @include m('sm') {
        @include tools-font-size(13.5px);
      }
      & > i {
        color: #fff;
        width: 30px;
        font-size: 18px;
        text-align: center;
        display: inline-block;
        margin-right: 30px;
      }
      &:hover {
        //color: #007de3;
        background-color: $linkHoverBg;
        color: $linkHoverColor;
        text-decoration: none;
      }
      &.active {
        background-color: $linkHoverBg;
        color: $linkHoverColor;
        &::before {
          content: ' ';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 3px;
          height: 100%;
          //background-color: #fc5622;
          background-color: var(--main-color);
        }
      }
      & > span {
        @include transition(all 0 350ms ease-in-out);
      }
    }
  }

  @include e(description) {
    margin-top: auto;
    padding: 16px;
    text-align: center;
  }

  @include e(sub-list) {
    background-color: $subLinkBg;
    //display: none;
    max-height: 0;
    overflow: hidden;
    @include transition(all 500ms ease-in-out);

    @include s(badge) {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      font-weight: 600;
      font-size: 11px;
      margin-left: 10px;
      background-color: #fc5622;
      color: #fff;
      border-radius: 15px;
      padding: 1px;
    }
    @include s(item) {
      @include tools-font-size(15px);
      position: relative;
      width: $sidebarWidth;
      display: block;
      padding: 12px 15px 12px 40px;
      color: $subLinkColor;
      text-decoration: none;
      white-space: nowrap;
      @include transition(all 300ms ease-in-out);
      & > i {
        color: #fff;
        width: 30px;
        font-size: 18px;
        text-align: center;
        display: inline-block;
        //margin-right: 30px;
        margin-right: 15px;
        @include transition(all 300ms ease-in-out);
      }
      &:hover {
        //color: #007de3;
        background-color: $subLinkHoverBg;
        color: $linkHoverColor;
        text-decoration: none;
      }
      &.active {
        background-color: $subLinkHoverBg;
        color: $linkHoverColor;
        &::before {
          content: ' ';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 3px;
          height: 100%;
          background-color: #fc5622;
        }
      }
      & > span {
        @include transition(all 0 350ms ease-in-out);
      }
    }
  }
}

@include bp-sm {
  .c-panel-sidebar {
    @include transform(translate(-#{$sidebarWidth}, 0));
    z-index: 100;
    @include e(sub-list-item) {
      padding: 12px 15px 12px 40px !important;
      & > i {
        margin-right: 15px !important;
      }
    }
  }
}
