@import '../tools/tools.font-size';
@import '../settings/settings.global';
@import '../tools/_tools.breakpoints';

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold;
}

h1,
.h1 {
  @include tools-font-size($font-size-h1);
  margin-bottom: 0.5rem;
  font-family: inherit;

  @include bp-md {
    @include tools-font-size($font-size-h4);
  }
}

h2,
.h2 {
  @include tools-font-size($font-size-h2);
  margin-bottom: 0.5rem;
  font-family: inherit;

  @include bp-md {
    @include tools-font-size($font-size-h5);
  }
}

h3,
.h3 {
  @include tools-font-size($font-size-h3);
  margin-bottom: 0.5rem;
  font-family: inherit;

  @include bp-md {
    @include tools-font-size($font-size-h6);
  }
}

h4,
.h4 {
  @include tools-font-size($font-size-h4, 44px);
  margin-bottom: 0.5rem;
  font-family: inherit;
}

h5,
.h5 {
  @include tools-font-size($font-size-h5);
  margin-bottom: 0.5rem;
  font-family: inherit;

  @include bp-md {
    @include tools-font-size($font-size-h6 - 0.2rem);
  }
}

h6,
.h6 {
  @include tools-font-size($font-size-h6);
  margin-bottom: 0.5rem;
  font-family: inherit;

  @include bp-md {
    @include tools-font-size($font-size-h6 - 0.5rem);
  }
}
